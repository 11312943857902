import React from 'react';

// Libraries (UI & external libs)
import { Box, Image, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

// Hooks, constants & in-house things
// ...

// Assets
import undrawEmpty from '../assets/img/undraw_empty.svg';

export default function Scheduled() {
  const { t } = useTranslation();

  return (
    <Box justify="center" align="center" fill className="loginBackground">
      <Box
        height="medium"
        width="medium"
        pad="medium"
        alignSelf="center"
        direction="column"
      >
        <Image src={undrawEmpty} fit="contain" />
        <Text textAlign="center">{t('scheduled.notimplemented')}</Text>
      </Box>
    </Box>
  );
}

// NOT AVAILABLE IN THE BACK FOR THE MOMENT
