import React, { useState, useEffect } from 'react';
import './Step3.scss';

import {
  faBicycle,
  faCarSide,
  faMotorcycle
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../../../components/Button/Button';
import StepperRadio from '../../../../components/StepperRadio/StepperRadio';

import useDeliveryStepper from '../../../../hooks/useDeliveryStepper';
import useApiV1 from '../../../../hooks/useApiV1';
import Loading from '../../../../components/Loading';

export function Step3(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState('bike');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transportsInfos, setTransportsInfos] = useState({});
  const [recommendedTransport, setRecommendedTransport] = useState('');

  const { currentStep, steps, updateStateData } = useDeliveryStepper();
  const { postDeliveryOptions } = useApiV1();

  const onDataChange = (newData) => {
    updateStateData({ ...steps[currentStep], ...newData });
  };

  const nextStep = () => {
    if (!canGoToNextStep()) return;
    onDataChange({
      amount: transportsInfos[value].deliveryPrice.value,
      amountTxt: transportsInfos[value].deliveryPrice.text,
    });
    props.nextStep();
  };

  const previousStep = async () => {
    props.previousStep();
  }

  const canGoToNextStep = () => {
    if (!steps[currentStep].vehicle) return false;

    return true;
  };

  useEffect(() => {
    if (loading === false) return;
    let oPayload = {
      storeId: steps[1].pickup_storeid,
      dropoffs: [
        {
          clientReference: steps[2].delivery_number,
          address: steps[2].delivery_address
        }
      ]
    };
    postDeliveryOptions(oPayload).then((res) => {
      if (res?.data?.transportsInfos) {
        setTransportsInfos(res.data.transportsInfos);
        setRecommendedTransport(res.data.recommendedTransport);
      }
    
      // Display error 
      if (res?.data?.error) {
        setError(res.data.message);
      } else {
        setError(null);
      }

      // Disable loading
      setLoading(false);
    });
  }, [loading]);

  useEffect(() => {
    if (value != steps[currentStep].vehicle) {
      onDataChange({ vehicle: value });
    }
  }, [value]);

  return (
    <>
      <div className="header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="heading">{t('newdelivery.stepper.step3.title')}</h1>
          <h4>{t('newdelivery.stepper.step')} 3</h4>
        </div>
      </div>
      <div className="body">
        <p className="subtitle">{t('newdelivery.stepper.step3.subtitle')}</p>

        {error && (
          <>
            <h2 className='txtError'>Oups :(</h2>
            <p className='txtError'>{error}</p>
          </>
        )}

        {loading 
          ? <Loading showText={false} />
          : (
            <>
              {transportsInfos && Object.keys(transportsInfos).map(function(transportName, i) {
                var oTransportation = transportsInfos[transportName];
                if (!oTransportation.available) return <></>;
                let transportIcon;
                switch (transportName) {
                  case 'car':
                    transportIcon = faCarSide;
                    break;
                  case 'bike':
                    transportIcon = faBicycle;
                    break;
                  case 'motorbike':
                    transportIcon = faMotorcycle;
                    break;
                  default:
                    transportIcon = faCarSide;
                    break;
                }
                return (
                  <VehicleOption
                    key={transportName + i}
                    icon={transportIcon}
                    title={t('labels.' + transportName)}
                    time="X min"
                    price={oTransportation.deliveryPrice?.text || '-'}
                    id={transportName}
                    selected={value === transportName}
                    recommended={recommendedTransport === transportName}
                    handleChange={() => {
                      setValue(transportName);
                    }}
                  />
                )
              })}
            </>
          )
        }

      </div>
      <div className="footer">
        <Button
          label={t('labels.previous')}
          handleClick={previousStep}
          white
        />
        <Button
          label={t('labels.next')}
          handleClick={nextStep}
          disabled={!canGoToNextStep()}
        />
      </div>
    </>
  );
}

function VehicleOption(props) {
  const { t } = useTranslation();

  return (
    <StepperRadio
      icon={props.icon}
      details={
        <>
          <h4>{props.title} {props.recommended ? '(recommandé)' : ''}</h4>
          <p>
            {/* {t('labels.arrival-in')} <span className="red">{props.time}</span> */}
            {t('labels.price-estimated')} {props.price}
          </p>
        </>
      }
      name="vehicule"
      id={props.id}
      handleChange={props.handleChange}
      selected={props.selected ?? false}
    />
  );
}
