export const env = {
    // -----------------------------------------------------
    // Mapbox
    // -----------------------------------------------------
    MAPBOX_API_KEY:     "pk.eyJ1IjoibWF4aW1lY29saXZlcmkiLCJhIjoiY2xqd21rZjZhMHczZjNtcWk3bDhsbXU0ayJ9.wxV8wgJD7Cpofdf9_46z6A",
  
    // -----------------------------------------------------
    // DEV
    // -----------------------------------------------------
    APP_ENV_TYPE:       "dev",
    API_V1_ROUTE_URL:   "https://new-dev-api.coliveri.com/v1",
    API_V1_STRIPE:      "https://api.stripe.com/v1",
    STRIPE_PRIV_KEY:    "sk_test_51LViUoLwkcmaNb5OoPKeF0sZ0I8bcvFEJcvzbNGNSMcbppKGJ7ytrSjm4JxxtvOZh02r0gvFM2GrL3goB2nkM0qc00qasXfqNW",
  
    // -----------------------------------------------------
    // PROD
    // -----------------------------------------------------
    // APP_ENV_TYPE:       "prod",
    // API_V1_ROUTE_URL:   "",
    
}
  