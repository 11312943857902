import { useState } from 'react';
import { env } from '../constants/env';

export default function useStripe() {
  const [loading, setLoading] = useState(false);

  // -------------------------------------
  // Axios CONFIG (auth)
  // -------------------------------------

  // -------------------------------------
  // PUT
  // -------------------------------------

  // -------------------------------------
  // POST
  // -------------------------------------
  const postPaymentMethod = async (payload) => {
    setLoading(true);

    var oHeaders = new Headers();
    oHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    oHeaders.append("Authorization", "Bearer " + env.STRIPE_PRIV_KEY);

    var oParams = new URLSearchParams();
    oParams.append("type", payload.type);
    oParams.append("card[number]", payload.card.number);
    oParams.append("card[exp_month]", payload.card.exp_month);
    oParams.append("card[exp_year]", payload.card.exp_year);
    oParams.append("card[cvc]", payload.card.cvc);

    var requestOptions = {
      method:     'POST',
      headers:    oHeaders,
      body:       oParams,
      redirect:   'follow'
    };

    return fetch(env.API_V1_STRIPE + "/payment_methods", requestOptions)
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Documentation: https://stripe.com/docs/api/tokens/create_card?lang=curl
   */
  const postCreateToken = async (payload) => {
    setLoading(true);

    var oHeaders = new Headers();
    oHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    oHeaders.append("Authorization", "Bearer " + env.STRIPE_PRIV_KEY);

    var oParams = new URLSearchParams();
    oParams.append("card[number]", payload.card.number);
    oParams.append("card[exp_month]", payload.card.exp_month);
    oParams.append("card[exp_year]", payload.card.exp_year);
    oParams.append("card[cvc]", payload.card.cvc);

    var requestOptions = {
      method:     'POST',
      headers:    oHeaders,
      body:       oParams,
      redirect:   'follow'
    };

    return fetch(env.API_V1_STRIPE + "/tokens", requestOptions)
      .then((res) => res)
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  };

  // -------------------------------------
  // HEAD
  // -------------------------------------

  // -------------------------------------
  // GET
  // -------------------------------------

  // -------------------------------------
  // PUT
  // -------------------------------------

  return {
    loading,
    postPaymentMethod,
    postCreateToken,
  };
}
