import React, { useRef, useState } from 'react';
import './Home.scss';

// Libraries (UI & external libs)
import { Box } from 'grommet';
import ReactMapGL, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

// Hooks, constants & in-house things
import { env } from '../../constants/env';
import Card from '../../components/Card/Card';
import { Stepper } from './Stepper/Stepper';

// Assets
import imgPinArrival from '../../assets/img/pin-arrival.png';
import imgPinPickup from '../../assets/img/pin-pickup.png';
import "mapbox-gl/dist/mapbox-gl.css";

export default function Home() {
  mapboxgl.accessToken = env.MAPBOX_API_KEY;
  const mapContainer = useRef(null);

  const [destinationLat, setDestinationLat] = useState(null);
  const [destinationLng, setDestinationLng] = useState(null);
  const [pickupLat, setPickupLat] = useState(null);
  const [pickupLng, setPickupLng] = useState(null);
  const [mapglZoom, setMapglZoom] = useState(5);
  const LAT_CENTER_FRANCE = 46.227638;
  const LNG_CENTER_FRANCE = 2.213749;

  const updatePickupPointOnMap = (lat, lng) => {
    setPickupLat(lat);
    setPickupLng(lng);
    setMapglZoom(12);
  }

  const updateDeliveryPointOnMap = (lat, lng) => {
    setDestinationLat(lat);
    setDestinationLng(lng);
    setMapglZoom(12);
  }

  return (
    <Box justify="center" align="center" fill className="page-container">
      <ReactMapGL
        ref={mapContainer}
        initialViewState={{
          latitude: LAT_CENTER_FRANCE,      // center of France (lat)
          longitude: LNG_CENTER_FRANCE,     // center of France (lng)
          zoom: mapglZoom,
        }}
        latitude={pickupLat || LAT_CENTER_FRANCE}
        longitude={pickupLng || LNG_CENTER_FRANCE}
        zoom={mapglZoom}
        onRender={(event) => event.target.resize()}
        interactive={true}
        mapStyle="mapbox://styles/mapbox/light-v11"
        attributionControl={false}
      >
        {pickupLat && (
          <Marker longitude={pickupLng} latitude={pickupLat}>
            <img alt={"Pin pickup"} src={imgPinPickup} height={128} width={128}/>
          </Marker>
        )}
        {destinationLat && (
          <Marker longitude={destinationLng} latitude={destinationLat}>
            <img alt={"Pin destination"} src={imgPinArrival} height={128} width={128}/>
          </Marker>
        )}
      </ReactMapGL>
      <div className="card-container">
        <Card className="new-delivery-form-container">
          <Stepper updateDeliveryPointOnMap={updateDeliveryPointOnMap} updatePickupPointOnMap={updatePickupPointOnMap}/>
        </Card>
      </div>
    </Box>
  );
}