import React, { useState, useEffect } from 'react';
import './Account.scss';

// Libraries (UI & external libs)
import { Box, Button, Text } from 'grommet';
import { Route, Switch, Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from '../../constants/routes';
import AccountDetails from './AccountDetails/AccountDetails';
import AccountAddressBook from './AccountAddressBook/AccountAddressBook';
import AccountBilling from './AccountBilling/AccountBilling';
import AccountAPI from './AccountAPI/AccountAPI';

export default function Account() {
  const { t } = useTranslation();
  const location = useLocation();
  let history = useHistory();
  const [currentLocation, setCurrentLocation] = useState(ROUTES.ACCOUNT);

  useEffect(() => {
    if (
      location.pathname == ROUTES.ACCOUNT.INDEX ||
      location.pathname == ROUTES.ACCOUNT.INDEX + '/'
    ) {
      history.push(ROUTES.ACCOUNT.DETAIlS);
    }
  }, [location]);

  history.listen(
    (location) => {
      setCurrentLocation(location.pathname);
    },
    [setCurrentLocation]
  );

  useEffect(() => {
    setCurrentLocation(history.location.pathname);
  }, [setCurrentLocation]);

  return (
    <Box direction="row" flex fill>
      <Box basis="1/4" align="center" gap="small" pad="large">
        <Link to={ROUTES.ACCOUNT.DETAIlS}>
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.ACCOUNT.DETAIlS
                    ? 'brand'
                    : 'dark-4'
                }
                size="small"
              >
                {t('account.details.title')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.ACCOUNT.BILLING}>
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.ACCOUNT.BILLING
                    ? 'brand'
                    : 'dark-4'
                }
                size="small"
              >
                {t('account.billing')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.ACCOUNT.ADDRESSBOOK}>
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.ACCOUNT.ADDRESSBOOK
                    ? 'brand'
                    : 'dark-4'
                }
                size="small"
              >
                {t('account.addressbook.title')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.ACCOUNT.API}>
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.ACCOUNT.API ? 'brand' : 'dark-4'
                }
                size="small"
              >
                {t('account.api.title')}
              </Text>
            }
          />
        </Link>
      </Box>
      <Box basis="3/4" pad="large" align="start">
        <Switch>
          <Route
            path={ROUTES.ACCOUNT.DETAIlS}
            component={AccountDetails}
          ></Route>
          <Route
            path={ROUTES.ACCOUNT.ADDRESSBOOK}
            component={AccountAddressBook}
          ></Route>
          <Route
            path={ROUTES.ACCOUNT.BILLING}
            component={AccountBilling}
          ></Route>
          <Route path={ROUTES.ACCOUNT.API} component={AccountAPI}></Route>
        </Switch>
      </Box>
    </Box>
  );
}
