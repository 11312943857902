import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useApiV1 from './useApiV1';
import { ROUTES } from '../constants/routes';

export default function useAuth() {
  let history = useHistory();
  const { postLogin } = useApiV1();

  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  const getToken = () => {
    return localStorage.getItem('token');
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  };

  const saveUser = (oUser) => {
    localStorage.setItem('user', JSON.stringify(oUser));
    setUser(oUser);
  };

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const deleteToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  // LOGIN USER
  const loginUser = async (data) => {
    const { username, password } = data;
    const res = await postLogin(username, password);

    if (res.data?.token) {
      saveToken(res.data.token);
      saveUser(res.data.user);
      return history.push(ROUTES.HOME);
    } else {
      return setError({
        message: "Une erreur s'est produite",
      });
    }
  };

  // LOGOUT USER
  const logoutUser = async () => {
    try {
      deleteToken();
      history.push('/');
    } catch (err) {
      console.log(err);
    }
  };

  return {
    token,
    user,
    getToken,
    setToken: saveToken,
    getUser,
    setUser: saveUser,
    loginUser,
    logoutUser,
    error,
  };
}
