export function capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
}

export function isEmailValid(email) {
    const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
    return regex.test(email);
}

export function isPhoneValid(phoneNumber) {
    const regex = new RegExp(/^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-9]{1}([ .-]?)(?:\d{2}\1?){3}\d{2}$/gm);
    return regex.test(phoneNumber);
}

export function creditCardValidation(cardNumber) {
    let sum = 0;
    let isEven = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
        let digit = parseInt(cardNumber.charAt(i), 10);

        if (isEven) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }
        sum += digit;
        isEven = !isEven;
    }
    // detectCardType(cardNumber);
    return sum % 10 === 0;
}    

export function validateExpirationDate(expirationMonth, expirationYear) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // January is 0

    if (expirationYear > currentYear) {
        return true;
    } else if (expirationYear === currentYear && expirationMonth >= currentMonth) {
        return true;
    }

    return false;
}

export function validateCVV(cvv) {
    const cvvPattern = /^[0-9]{3,4}$/;
    return cvvPattern.test(cvv);
}