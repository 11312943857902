import React, { useState, useContext, useEffect } from 'react';
import './AppBar.scss';

// Libraries (UI & external libs)
import { Image, Header, Button, Box, Text } from 'grommet';
import { Link, useHistory } from 'react-router-dom';
import { AddCircle, User } from 'grommet-icons';
import { useTranslation } from 'react-i18next';

// Hooks, constants & in-house things
import useAuth from '../hooks/useAuth';
import { ROUTES } from '../constants/routes';

// Assets
import imgLogo from '../assets/img/logo.png';
import { UserContext } from '../contexts/UserContext';

export default function AppBar() {
  // Hooks
  const { logoutUser } = useAuth();
  let history = useHistory();
  const { t } = useTranslation();
  const { getUser } = useContext(UserContext);

  // States
  const [currentLocation, setCurrentLocation] = useState(ROUTES.HOME);
  const [fullName, setFullName] = useState(null);

  // Route change listener
  history.listen((location) => {
    setCurrentLocation(location.pathname);
  });

  useEffect(() => {
    try {
      setFullName(getUser().name);
    } catch (e) {}
  }, [fullName]);

  return (
    <Header
      direction="row"
      background="white"
      alignContent="between"
      pad={{ left: 'small', right: 'small', vertical: 'small' }}
    >
      <Box direction="row" gap="small" align="center">
        <Link to={ROUTES.HOME} className="nav-link">
          <Image src={imgLogo} height={28} width={28} />
        </Link>
        <Link to={ROUTES.HOME} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            justify="center"
            icon={
              <AddCircle
                color={currentLocation === ROUTES.HOME ? 'focus' : 'dark-4'}
              />
            }
            label={
              <Text
                color={currentLocation === ROUTES.HOME ? 'brand' : 'dark-4'}
                size="small"
              >
                {t('appbar.newdelivery')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.INPROGRESS} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.INPROGRESS ? 'brand' : 'dark-4'
                }
                size="small"
              >
                {t('appbar.inprogress')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.SCHEDULED} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={
                  currentLocation === ROUTES.SCHEDULED ? 'brand' : 'dark-4'
                }
                size="small"
              >
                {t('appbar.scheduled')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.HISTORY} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={currentLocation === ROUTES.HISTORY ? 'brand' : 'dark-4'}
                size="small"
              >
                {t('appbar.history')}
              </Text>
            }
          />
        </Link>
        <Link to={ROUTES.STORES} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            label={
              <Text
                color={currentLocation === ROUTES.STORES ? 'brand' : 'dark-4'}
                size="small"
              >
                {t('appbar.stores')}
              </Text>
            }
          />
        </Link>
      </Box>
      <Box direction="row" gap="small" align="center">
        <Link to={ROUTES.ACCOUNT.INDEX} className="nav-link">
          <Button
            plain
            hoverIndicator={true}
            icon={
              <User
                color={
                  currentLocation.startsWith(ROUTES.ACCOUNT.INDEX) ? 'brand' : 'dark-4'
                }
              />
            }
            label={
              <Text
                color={
                  currentLocation.startsWith(ROUTES.ACCOUNT.INDEX) ? 'brand' : 'dark-4'
                }
                size="small"
              >
                {fullName || t('appbar.myaccount')}
              </Text>
            }
          />
        </Link>
        <Button
          primary
          color="focus"
          onClick={logoutUser}
          label={
            <Text color="white" size="small">
              {t('general.logout')}
            </Text>
          }
        />
      </Box>
    </Header>
  );
}
