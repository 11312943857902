import React, { useState, useEffect } from 'react';
import './AccountDetails.scss';

import FormInput from '../../../components/FormInput/FormInput';
import Button from '../../../components/Button/Button';
import { CheckBox, Select } from 'grommet';
import Card from '../../../components/Card/Card';
import { useTranslation } from 'react-i18next';
import useApiV1 from '../../../hooks/useApiV1';
import i18next from 'i18next';
import { toast } from 'react-toastify';

export default function AccountDetails(props) {
  const { t } = useTranslation();
  const { putPassword, getBusinessInfo, putBusinessInfo } = useApiV1()

  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "fr");
  const optionsLanguages = [{
    label: "🇺🇸 EN",
    value: "en"
  }, {
    label: "🇫🇷 FR",
    value: "fr"
  }];
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [billing, setBilling] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    try {
      getBusinessInfo().then((res) => {
        if (res.status < 300) {
          var oData = res.data;
          console.log(oData)
          setFirstname(oData.contact?.firstName);
          setLastname(oData.contact?.lastName);
          setBilling(oData.paymentMethod);
          setPhone(oData.contact?.phone);
          setEmail(oData.contact?.email);
        }
      })
    } catch (e) {}
  }, []);

  const updateUserInfo = () => {
    var oPayload = {
      name: firstname + " " + lastname,
      phone,
      contact: {
        firstName: firstname,
        lastName: lastname,
        phone,
        email
      },
    };
    putBusinessInfo(oPayload).then((res) => {
      if (res.status === 204) {
        toast.success("Informations mises à jour avec succès.");
      } else {
        toast.error(res.data?.message || "Une erreur s'est produite pendant la mise à jour de vos informations");
      }
    });
  } 

  const updatePassword = () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("Le mot de passe n'est pas identique");
      return;
    }

    putPassword(actualPassword, newPassword).then((res) => {
      if (res.status === 204) {
        toast.success("Mot de passe changé avec succès");
        setNewPassword("");
        setActualPassword("");
        setConfirmNewPassword("");
      } else {
        toast.error(res.data?.message || "Une erreur s'est produite pendant la mise à jour du mot de passe");
      }
    });
  };

  return (
    <>
      <Card className="form-container">
      <h1 className="heading">{t('labels.information')}</h1>
        <form>
          <div className="input-grid">
            <FormInput
              label={t('labels.firstname')}
              name="firstname"
              id="firstname"
              value={firstname}
              handleChange={(e) => setFirstname(e.target.value)}
              placeholder={t('labels.firstname')}
            />
            <FormInput
              label={t('labels.lastname')}
              name="lastname"
              id="lastname"
              value={lastname}
              handleChange={(e) => setLastname(e.target.value)}
              placeholder={t('labels.lastname')}
            />
            <FormInput
              label={t('labels.phone')}
              name="phone"
              id="phone"
              value={phone}
              handleChange={(e) => setPhone(e.target.value)}
              placeholder={t('labels.phone')}
            />
            <FormInput
              label={t('labels.email')}
              name="email"
              id="email"
              value={email}
              handleChange={(e) => setEmail(e.target.value)}
              placeholder={t('labels.email')}
            />
          </div>
          <FormInput
            label={t('labels.payment-method')}
            name="billing"
            id="billing"
            value={billing}
            disabled
            placeholder={t('labels.payment-method')}
          />
          <div className="button-container">
            <Button label={t('labels.savechanges')} handleClick={() => updateUserInfo()} />
          </div>
        </form>
      </Card>
      <Card className="form-container">
        <h1 className="heading">{t('labels.language')}</h1>
        <Select
          options={optionsLanguages}
          labelKey="label"
          valueKey={{ key: 'value', reduce: true }}
          value={language}
          onChange={({ value: nextValue }) => {
            localStorage.setItem("i18nextLng", nextValue);
            i18next.changeLanguage(nextValue);
            setLanguage(nextValue);
          }}
        />
      </Card>
      <Card className="form-container">
        <h1 className="heading">{t('labels.password')}</h1>
        <p>{t('account.details.changepasswordtext')}</p>
        <FormInput
          label={t('labels.currentpassword')}
          name="actual_password"
          id="actual_password"
          value={actualPassword}
          handleChange={(e) => setActualPassword(e.target.value)}
          placeholder={t('labels.currentpassword')}
          type="password"
        />
        <FormInput
          label={t('labels.newpassword')}
          name="new_password"
          id="new_password"
          value={newPassword}
          handleChange={(e) => setNewPassword(e.target.value)}
          placeholder={t('labels.newpassword')}
          type="password"
        />
        <FormInput
          label={t('labels.confirmnewpassword')}
          name="confirmnew_password"
          id="confirmnew_password"
          value={confirmNewPassword}
          handleChange={(e) => setConfirmNewPassword(e.target.value)}
          placeholder={t('labels.confirmnewpassword')}
          type="password"
        />
        <div className="button-container">
          <Button label={t('labels.changemypassword')} handleClick={() => updatePassword()} />
        </div>
      </Card>
      <Card className="form-container">
        <h1 className="heading">{t('labels.notification')}</h1>
        <CheckBox label={t("labels.ordertrackingemail")} />
      </Card>
      <Card className="form-container">
        <h1 className="heading">{t('labels.account')}</h1>
        <p>{t('account.details.deleteaccounttext')}</p>
        <FormInput
          label={t('labels.currentpassword')}
          name="delete_password"
          id="delete_password"
          placeholder={t('labels.currentpassword')}
          type="password"
        />
        <div className="button-container">
          <Button label={t('labels.deletemyaccount')} disabled />
        </div>
      </Card>
    </>
  );
}
