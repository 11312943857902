import React from 'react';
import './Button.scss';

export default function Button(props) {
  function getColorClass() {
    let color = 'button--focus';
    if (props.primary) {
      color = 'button--primary';
    } else if (props.success) {
      color = 'button--success';
    } else if (props.danger) {
      color = 'button--danger';
    } else if (props.accent || props.accent1) {
      color = 'button--accent-1';
    } else if (props.neutral) {
      color = 'button--neutral';
    } else if (props.placeholder) {
      color = 'button--placeholder';
    } else if (props.white) {
      color = 'button--white';
    }

    return color;
  }

  return (
    <button
      type={props.type ?? 'button'}
      className={`button ${getColorClass()}`}
      disabled={props.disabled ?? false}
      onClick={props.handleClick}
    >
      {props.children ?? props.label}
    </button>
  );
}
