import { useState } from 'react';
import axios from 'axios';
import { env } from '../constants/env';

export default function useApiV1() {
  const [loading, setLoading] = useState(false);

  // -------------------------------------
  // Axios CONFIG (auth)
  // -------------------------------------
  axios.interceptors.request.use(function (config) {
    let sToken = localStorage.getItem('token');
    if (sToken) {
      config.headers['Authorization'] = 'Bearer ' + sToken;
    }
    return config;
  });

  // -------------------------------------
  // PUT
  // -------------------------------------
  const putPassword = async (oldPassword, newPassword) => {
    setLoading(true);
    return axios
      .patch(env.API_V1_ROUTE_URL + '/users/password', {
        oldPassword,
        newPassword
      })
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const patchTriggerSearchRiderStatusForIntegration = (storeId, integratorName, newStatus) => {
    setLoading(true);
    return axios
      .patch(env.API_V1_ROUTE_URL + `/stores/${storeId}/integrations/${integratorName}/${newStatus}`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  const putBusinessInfo = async (payload) => {
    setLoading(true);
    return axios
      .put(env.API_V1_ROUTE_URL + '/business/me', payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const putStore = async (storeId, payload) => {
    setLoading(true);
    return axios
      .put(env.API_V1_ROUTE_URL + '/stores/' + storeId, payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  // -------------------------------------
  // POST
  // -------------------------------------
  const postLogin = async (username, password) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/auth/login', {
        email: username,
        password: password,
      })
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const postCompleteAddress = async (address) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/addresses/autocomplete?address=' + encodeURI(address), {})
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const postEta = async (payload) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/jobs/eta', payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  const postCard = async (token) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/business/payment-methods', {
        token
      })
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  const postJob = async (payload) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/jobs', payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  const postStore = async (payload) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/stores', payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  const postDeliveryOptions = async (payload) => {
    setLoading(true);
    return axios
      .post(env.API_V1_ROUTE_URL + '/jobs/delivery/options', payload)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  }

  // -------------------------------------
  // HEAD
  // -------------------------------------

  // -------------------------------------
  // GET
  // -------------------------------------

  const getDeliveryTracking = async (deliveryId, deliveryTrackingToken) => {
    setLoading(true);
    return axios
      .get(env.API_V1_ROUTE_URL + '/deliveries/' + deliveryId + '/' + deliveryTrackingToken, {})
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getParcelReferenceIsUnique = async (storeId, reference) => {
    setLoading(true);
    return axios
      .get(env.API_V1_ROUTE_URL + '/deliveries/stores/' + storeId + '/client-reference/' + reference + '/exists', {})
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getStores = async (cityId) => {
    setLoading(true);

    let url = env.API_V1_ROUTE_URL + '/stores';
    if (cityId) {
      url += '?cityId=' + encodeURI(cityId);
    }

    return axios
      .get(url)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getCards = async () => {
    setLoading(true);

    let url = env.API_V1_ROUTE_URL + '/business/cards';

    return axios
      .get(url)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getCities = async (search) => {
    setLoading(true);
    return axios
      .get(env.API_V1_ROUTE_URL + '/cities?nameSubstring=' + encodeURI(search))
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getStore = async (storeId) => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/stores/${storeId}`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getPlannings = async () => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/plannings`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getHubriseAuthUrl = async (storeId, integrationName) => {
    setLoading(true);
    return axios
      .get(
        `${env.API_V1_ROUTE_URL}/stores/${storeId}/integrations/${integrationName}/oauth-url`
      )
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getTriggerSearchRiderStatusesForIntegration = async (integrationName) => {
    setLoading(true);
    return axios
      .get(
        `${env.API_V1_ROUTE_URL}/stores/integrations/${integrationName}/trigger-search-rider-statuses`
      )
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getActiveJobs = async () => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/jobs/active`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getJobsHistory = async () => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/jobs/history`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getJob = async (jobId) => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/jobs/${jobId}`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const getBusinessInfo = async () => {
    setLoading(true);
    return axios
      .get(`${env.API_V1_ROUTE_URL}/business/me`)
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  // -------------------------------------
  // PUT
  // -------------------------------------
  const putDisableIntegration = async (storeId, integrationName) => {
    setLoading(true);
    return axios
      .put(
        `${env.API_V1_ROUTE_URL}/stores/${storeId}/integrations/${integrationName}/disable`
      )
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  // -------------------------------------
  // DELETE
  // -------------------------------------
  const deleteStore = async (storeId) => {
    setLoading(true);
    return axios
      .delete(
        `${env.API_V1_ROUTE_URL}/stores/${storeId}`
      )
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCard = async (cardId) => {
    setLoading(true);
    return axios
      .delete(
        `${env.API_V1_ROUTE_URL}/business/cards/${cardId}`
      )
      .then((res) => res)
      .catch((err) => err.response)
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    putPassword,
    putBusinessInfo,
    putStore,
    patchTriggerSearchRiderStatusForIntegration,
    getDeliveryTracking,
    getParcelReferenceIsUnique,
    getCities,
    getCards,
    getStores,
    getStore,
    getPlannings,
    getHubriseAuthUrl,
    getTriggerSearchRiderStatusesForIntegration,
    putDisableIntegration,
    getActiveJobs,
    getJobsHistory,
    getBusinessInfo,
    getJob,
    postJob,
    postStore,
    postEta,
    postCard,
    postDeliveryOptions,
    postLogin,
    postCompleteAddress,
    deleteStore,
    deleteCard
  };
}
