import React, { useContext } from 'react';
import './Login.scss';

// Libraries (UI & external libs)
import { Box, Image } from 'grommet';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Hooks, constants & in-house things
import Error from '../../components/Error';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import { UserContext } from '../../contexts/UserContext';

// Assets
import imgLogo from '../../assets/img/logo.png';
import Card from '../../components/Card/Card';
import FormInput from '../../components/FormInput/FormInput';
import Button from '../../components/Button/Button';

export default function Login() {
  const { t } = useTranslation();

  const { values, handleChange } = useForm({
    initialValues: {
      username: 'joey.bronner+lunch&co@gmail.com',
      password: 'rR0VRi',
    },
  });

  const { loginUser, error } = useAuth();
  const { getToken } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser(values);
  };

  if (getToken()) {
    return <Redirect to="/home" />;
  }

  return (
    <Box fill className="loginBackground">
      <div className="header">
        <Image fit="cover" src={imgLogo} />
        <h1>coliveri</h1>
      </div>
      <Card className="form-container">
        <h1 className="heading">{t('authentication.login.title')}</h1>
        <FormInput
          name="username"
          placeholder={t('labels.email')}
          label={t('labels.email')}
          value={values.username}
          handleChange={handleChange}
        />
        <FormInput
          name="password"
          type="password"
          placeholder={t('labels.password')}
          label={t('labels.password')}
          value={values.password}
          handleChange={handleChange}
        />

        {error && <Error error={error.message} />}

        <div className="inlineForm__submit">
          <Link to="/register" className="link">
            <span>{t('labels.i-do-not-have-an-account')}</span>
          </Link>
          <Button secondary label={t('labels.login')} handleClick={handleLogin} />
        </div>
      </Card>

      <div className="footer">
        <a href="#" className="link">
        {t('labels.legal-notices')}
        </a>
        <a href="#" className="link">
        {t('labels.privacy-policy')}
        </a>
        <span className="footer__copyright">© 2023 - Coliveri</span>
      </div>
    </Box>
  );
}
