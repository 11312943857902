import React, { useState, useEffect } from 'react';
import './Step2.scss';

import { useTranslation } from 'react-i18next';

import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';
import { CheckBox, TextInput } from 'grommet';

import useDeliveryStepper from '../../../../hooks/useDeliveryStepper';
import useApiV1 from '../../../../hooks/useApiV1';

import { toast } from 'react-toastify';
import { isEmailValid, isPhoneValid } from '../../../../utils/Utils';

export function Step2(props) {
  const { t } = useTranslation();
  const [substep, setSubstep] = useState(1);
  const [btnNextDisabled, setBtnNextDisabled] = useState(false);

  const { currentStep, steps, updateStateData } = useDeliveryStepper();
  const { getParcelReferenceIsUnique, postDeliveryOptions } = useApiV1();

  const nextSubstep = async () => {
    if (!canGoToNextStep()) return;

    if (substep == 1) {
      setBtnNextDisabled(true);
      let oPayload = {
        storeId: steps[1].pickup_storeid,
        dropoffs: [
          {
            clientReference: steps[2].delivery_number || 'checkconsistance-' + new Date().getTime(),
            address: steps[2].delivery_address
          }
        ]
      };
      postDeliveryOptions(oPayload).then((res) => {
      
        // Display error 
        if (res?.data?.error) {
          setBtnNextDisabled(false);
          return toast.error(res.data.message || "L'adresse de destination pose problème, veuillez la changer.")
        }
  
        // Go to next step
        setBtnNextDisabled(false);
        return setSubstep((s) => s + 1);
      });
    }

    if (substep == 2) {
      // Phone check
      if (!isPhoneValid(steps[2].delivery_phone)) {
        toast.error("Le numéro de téléphone n'est pas valide. Veuillez corriger s'il vous plaît.");
        return;
      }

      // Email check
      if (!isEmailValid(steps[2].delivery_email)) {
        toast.error("L'adresse email n'est pas valide. Veuillez corriger s'il vous plaît.");
        return;
      }

      // Order number check
      var resUnique = await getParcelReferenceIsUnique(steps[1].pickup_storeid, steps[2].delivery_number);
      if (resUnique?.data?.exists === true) {
        toast.error("Ce numéro de colis existe déjà. Merci de renseigner un numéro de colis unique.");
        return;
      }

      return props.nextStep();
    }

  };

  const previousStep = async () => {
    if (substep > 1) {
      setSubstep((s) => s - 1);
    } else {
      props.previousStep();
    }
  }

  const canGoToNextStep = () => {
    if (substep === 1) {
      if (steps[currentStep].delivery_address.length === 0) return false;
    } else {
      let res = true;
      for (const [key, value] of Object.entries(steps[currentStep])) {
        if (key != 'delivery_additional' && key != 'delivery_add') {
          if (!value) {
            res = false;
          }
        }
      }

      return res;
    }

    if (btnNextDisabled) {
      return false;
    }

    return true;
  };

  const onDataChange = (newData) => {
    updateStateData({ ...steps[currentStep], ...newData });
  };

  const onInputChanged = (e) => {
    const d = {};
    if (e.target.type === 'checkbox') {
      d[e.target.name] = e.target.checked;
    } else {
      d[e.target.name] = e.target.value;
    }
    onDataChange(d);
  };

  useEffect(() => {
    console.log(steps);
  }, [steps]);

  return (
    <>
      <div className="header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="heading">{t('newdelivery.stepper.step2.title')}</h1>
          <h4>{t('newdelivery.stepper.step')} 2</h4>
        </div>
      </div>
      <div className="body">
        <p className="subtitle">{t('newdelivery.stepper.step2.subtitle')}</p>
        {substep === 1 && (
          <Substep1
            data={steps[currentStep]}
            handleInputChange={onInputChanged}
            updateDeliveryPointOnMap={props.updateDeliveryPointOnMap}
          />
        )}
        {substep === 2 && (
          <Substep2
            data={steps[currentStep]}
            handleInputChange={onInputChanged}
          />
        )}
      </div>
      <div className="footer">
        <Button
          label={t('labels.previous')}
          handleClick={previousStep}
          white
        />
        <Button
          label={t('labels.next')}
          handleClick={nextSubstep}
          disabled={!canGoToNextStep()}
        />
      </div>
    </>
  );
}

function Substep1(props) {
  const { t } = useTranslation();
  const { postCompleteAddress } = useApiV1();

  const [suggestions, setSuggestions] = useState([]);

  return (
    <>
      <TextInput
        name="delivery_address"
        value={props.data['delivery_address']}
        onChange={(e) => {
          let address = e.target.value;
          if (address.length > 4) {
            postCompleteAddress(address).then((res) => {
              if (res.data?.predictions?.length > 0) {
                let aPredictions = res.data.predictions;
                let aSuggestions = aPredictions.map(oPrediction => ({
                  label: oPrediction.formattedAddress,
                  value: oPrediction.formattedAddress,
                  lat: oPrediction.latitude,
                  lng: oPrediction.longitude,
                }))
                setSuggestions(aSuggestions);
              }
            })
          }
          return props.handleInputChange(e);
        }}
        onSuggestionSelect={(e) => {
          props.handleInputChange({ 
            target: { 
              name: "delivery_address",
              value: e.suggestion.label
            }
          });
          props.updateDeliveryPointOnMap(e.suggestion.lat, e.suggestion.lng);
        }}
        suggestions={suggestions}
        defaultSuggestion={1}
        aria-label={t('labels.search-address')}
        placeholder={t('labels.search-address-with-format')}
        className='txtInputSuggestions'
      />
    </>
  );
}

function Substep2(props) {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        label={t('labels.search-address')}
        placeholder={t('labels.search-address')}
        name="delivery_address"
        disabled
        handleChange={props.handleInputChange}
        value={props.data['delivery_address']}
      />
      <div className="splitted">
        <FormInput
          label={'*' + t('labels.firstname')}
          placeholder={t('labels.firstname')}
          name="delivery_firstname"
          handleChange={props.handleInputChange}
          value={props.data['delivery_firstname']}
        />
        <FormInput
          label={'*' + t('labels.lastname')}
          placeholder={t('labels.lastname')}
          name="delivery_name"
          handleChange={props.handleInputChange}
          value={props.data['delivery_name']}
        />
      </div>
      <FormInput
        label={'*' + t('labels.company')}
        placeholder={t('labels.company')}
        name="delivery_company"
        handleChange={props.handleInputChange}
        value={props.data['delivery_company']}
      />
      <div className="splitted">
        <FormInput
          label={'*' + t('labels.phone')}
          placeholder={t('labels.phone')}
          name="delivery_phone"
          handleChange={props.handleInputChange}
          value={props.data['delivery_phone']}
        />
        <FormInput
          label={'*' + t('labels.email')}
          placeholder={t('labels.email')}
          name="delivery_email"
          handleChange={props.handleInputChange}
          value={props.data['delivery_email']}
        />
      </div>
      <FormInput
        label={t('labels.address-additional')}
        placeholder={t('labels.address-additional')}
        name="delivery_additional"
        handleChange={props.handleInputChange}
        value={props.data['delivery_additional']}
      />
      <FormInput
        label={'*' + t('labels.order-number')}
        placeholder={t('labels.order-number')}
        name="delivery_number"
        handleChange={props.handleInputChange}
        value={props.data['delivery_number']}
      />
      <FormInput
        label={'*' + t('labels.order-content')}
        placeholder={t('labels.order-content')}
        name="delivery_content"
        handleChange={props.handleInputChange}
        value={props.data['delivery_content']}
      />
      <CheckBox
        label={t('labels.addressbook-add')}
        name="delivery_add"
        onChange={props.handleInputChange}
        checked={props.data['delivery_add']}
      />
    </>
  );
}
