import React, {useState} from 'react';
import './Delivery.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faCube, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { capitalize } from '../../utils/Utils';

import Card from '../Card/Card';
import Iframe from '../Iframe/Iframe';
import Tracking from '../../pages/Tracking/Tracking';

export default function Delivery(props) {

  const [showTracking, setShowTracking] = useState(false);

  const openIframe = async (url) => {
    setShowTracking(true);
  };

  return (
    <Card className="delivery">
      <div className="delivery__header">
        <h4>{props.reference}</h4>
        {props.status !== 'pending' && (
          <div className="integration-edit" onClick={() => openIframe("/tracking")}>
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
        )}
      </div>

      {showTracking && (
        <Iframe 
          title={'Tracking ' + props.reference}
          handleClose={() => setShowTracking(false)}
          hideCTA>
            <Tracking deliveryId={props.id} deliveryTrackingToken={props.trackingSecureToken} />
        </Iframe>)}

      {props.hideTime ?? false ? (
        ''
      ) : (
        <div className="delivery__time">
          {capitalize(props.status)}
        </div>
      )}

      <div className="delivery__category">
        <h5 className="delivery__category-title">Point de retrait</h5>
        <div className="delivery__category-content">
          <div className="delivery__category-icon">
            <FontAwesomeIcon icon={faCube} size="xl" />
          </div>
          <div className="delivery__category-body">
            <div className="delivery__category-body-title">
              {props.departure.title}
            </div>
            <div className="delivery__category-body-subtitle">
              {props.departure.subtitle}
            </div>
          </div>
        </div>
      </div>

      <div className="separator"></div>

      <div className="delivery__category">
        <h5 className="delivery__category-title">Point de livraison</h5>
        <div className="delivery__category-content">
          <div className="delivery__category-icon">
            <FontAwesomeIcon
              icon={faFlag}
              size="xl"
              style={{ color: '#82d794' }}
            />
          </div>
          <div className="delivery__category-body">
            <div className="delivery__category-body-title">
              {props.arrival.title}
            </div>
            <div className="delivery__category-body-subtitle">
              {props.arrival.subtitle}
            </div>
          </div>
        </div>
      </div>
      <div className="delivery__category"></div>
    </Card>
  );
}
