import React from 'react';
import './Iframe.scss';

import { Card } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '../Button/Button';

export default function Iframe(props) {
  const { t } = useTranslation();

  return (
    <div className="iframe">
      <div className="iframe__bg" onClick={props.handleClose}></div>
      <div className="iframe__card">
        <Card width={"large"} height={"large"} background={"white"}>
          <div className="iframe__card-header">
            <h1 className="heading">{props.title}</h1>
            {props.hideClose ?? false ? (
              ''
            ) : (
              <FontAwesomeIcon
                icon={faXmark}
                size="2xl"
                className="iframe__close"
                onClick={props.handleClose}
              />
            )}
          </div>
          
          {props.children}

          {!props.hideCTA && (
            <div className="iframe__footer">
              <button
                type="button"
                className="cancel-btn"
                onClick={props.handleClose}
              >
                {props.cancelText ?? t('labels.cancel')}
              </button>
              <Button
                type="button"
                disabled={props.confirmDisabled}
                label={props.confirmText ?? t('labels.save')}
                handleClick={props.handleConfirm}
                primary
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
