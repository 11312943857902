import React, { useReducer } from 'react';

export const DeliveryStepperContext = React.createContext();

export const DeliveryStepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStepperState);

  return (
    <DeliveryStepperContext.Provider value={[state, dispatch]}>
      {children}
    </DeliveryStepperContext.Provider>
  );
};

// Reducer
export const INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP';
export const DECREMENT_CURRENT_STEP = 'DECREMENT_CURRENT_STEP';
export const UPDATE_STATE_DATA = 'UPDATE_STEP_DATA';
export const CLEAR_STATE = 'CLEAR_STATE';

const DEBUG = false;

export const defaultStepperState = {
  steps: {
    1: {
      pickup_address: '',
      pickup_firstname: '',
      pickup_name: '',
      pickup_company: '',
      pickup_phone: '',
      pickup_email: '',
      pickup_additional: '',
      pickup_add: false,
    },
    2: {
      delivery_address: '',
      delivery_firstname: DEBUG ? 'John' : '',
      delivery_name: DEBUG ? 'Doe' : '',
      delivery_company: DEBUG ? 'Fake Company' : '',
      delivery_phone: DEBUG ? '0612345678' : '',
      delivery_email: DEBUG ? 'johndoe@fakecompany.com' : '',
      delivery_additional: DEBUG ? 'Interphone 123AB' : '',
      delivery_number: '',
      delivery_content: DEBUG ? 'Fleurs' : '',
      delivery_add: false,
    },
    3: {
      vehicle: 'bike',
    },
    4: {
      timetype: 'asap',
      delivery_date: '',
      delivery_timeSlot: '',
      payment_type: '',
      card_number: DEBUG ? '4242424242424242' : '',
      exp_month: DEBUG ? '4' : '',
      exp_year: DEBUG ? '32' : '',
      cvc: DEBUG ? '123' : '',
      card_name: DEBUG ? 'John Doe' : '',
      accept: false,
    },
  },
  currentStep: 1,
  maxSteps: 4,
};

export const reducer = (state = defaultStepperState, action) => {
  const { currentStep, maxSteps } = state;
  const { type, payload } = action;

  switch (type) {
    case CLEAR_STATE:
      return { ...defaultStepperState };

    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep < maxSteps ? currentStep + 1 : currentStep,
      };

    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };

    case UPDATE_STATE_DATA:
      let steps = { ...state.steps };
      steps[currentStep] = payload.step;
      return {
        ...state,
        steps: { ...steps },
      };

    default:
      return state;
  }
};
