import React, { useContext, useCallback } from 'react';
import {
  CLEAR_STATE,
  DECREMENT_CURRENT_STEP,
  DeliveryStepperContext,
  INCREMENT_CURRENT_STEP,
  UPDATE_STATE_DATA,
} from '../contexts/DeliveryStepperContext';

export default function useDeliveryStepper() {
  const [state, dispatch] = useContext(DeliveryStepperContext);
  const { currentStep, steps, maxSteps } = state;

  if (!DeliveryStepperContext) {
    throw new Error(
      'useDeliveryStepper should be used inside DeliveryStepperProvider'
    );
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const updateStateData = (data) => {
    dispatch({
      type: UPDATE_STATE_DATA,
      payload: { step: data },
    });
  };

  const clearState = useCallback(() => {
    dispatch({
      type: CLEAR_STATE,
    });
  }, [dispatch]);

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    updateStateData,
    clearState,
    steps,
    currentStep,
    maxSteps,
  };
}
