import React from 'react';
import { useTranslation } from 'react-i18next';
import './Store.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlug, faTrash, faLocationPin } from '@fortawesome/free-solid-svg-icons';

import Card from '../Card/Card';

export default function Store(props) {

    const { t } = useTranslation();

    return (
        <Card className="store">

            {/* Header */}
            <div className="store__header">
                <h4>{props.name}</h4>
                <div className='row'>
                    <div className="integration-edit" onClick={() => props.setShowModalStoreUpdate()}>
                        <FontAwesomeIcon icon={faPencil} />
                    </div>
                    <div className="integration-edit" onClick={() => props.setShowModalStoreDelete()}>
                        <FontAwesomeIcon icon={faTrash} style={{ color: '#f95b5b' }} />
                    </div>
                </div>
            </div>
            <div className="store__category-body-subtitle">
                {props.formattedAddress}
            </div>
            <div className="store__category-body-subtitle">
                {props.phone}
            </div>

            <div className="separator"></div>

            {/* Contact */}
            <div className="store__category">
                <h5 className="store__category-title">
                    <FontAwesomeIcon icon={faLocationPin} size="xl" />
                    <span style={{marginLeft: '4px'}}>{t('stores.titlecontact')}</span>
                </h5>
                <div className="store__category-content">
                <div className="store__category-body">
                    <div className="store__category-body-title">
                    {(props.contact.firstName || '') + ' ' + (props.contact.lastName || '')}
                    </div>
                    <div className="store__category-body-title">
                    {props.contact.email}
                    </div>
                    <div className="store__category-body-title">
                    {props.contact.phone}
                    </div>
                </div>
                </div>
            </div>

            {/* Integrations */}
            <div className="store__category">
                <h5 className="store__category-title">
                    <FontAwesomeIcon icon={faPlug} size="xl" />
                    <span style={{marginLeft: '4px'}}>{t('stores.titleintegrations')}</span>
                </h5>
                <div className='row'>
                    <div className={`store__int ${props.nbIntegrationsDone > 0 ? "done" : "available"}`}>
                        {props.nbIntegrationsDone + ' ' + t('stores.titleintegrationsdone')}
                    </div>
                    <div className={"store__int available"}>
                        {props.nbIntegrationsAvailable + ' ' + t('stores.titleintegrationsavailable')}
                    </div>
                </div>
            </div>
        </Card>
    );
}
