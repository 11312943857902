import React, { useContext } from 'react';

// Libraries (UI & external libs)
import { Route, Redirect } from 'react-router-dom';

// Hooks, constants & in-house things
import { UserContext } from '../contexts/UserContext';
import AppBar from '../sections/AppBar';

export default function PrivateRoute(props) {
  const { getToken } = useContext(UserContext);
  const { component: Component, ...rest } = props;

  if (getToken()) {
    return (
      <>
        <AppBar />
        <Route {...rest} render={(props) => <Component {...props} />} />
      </>
    );
  } else {
    return <Redirect to="/login" />;
  }
}
