import React from 'react';

import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckBox } from 'grommet';

import FormInput from '../FormInput/FormInput';
import useDeliveryStepper from '../../hooks/useDeliveryStepper';

export default function CreditCardForm(props) {

  const { steps } = useDeliveryStepper();

  return (
    <form>
      <FormInput
        label="Numéro de carte"
        placeholder="Numéro de carte"
        name="card_number"
        id="card_number"
        handleChange={props.onInputChanged}
        value={props.card_number || steps[4].card_number}
      />
      <div className="input-grid">
        <FormInput
          label="Mois d'expiration"
          placeholder="Mois d'expiration"
          name="exp_month"
          id="exp_month"
          handleChange={props.onInputChanged}
          value={props.exp_month || steps[4].exp_month}
        />
        <FormInput
          label="Année d'expiration"
          placeholder="Année d'expiration"
          name="exp_year"
          id="exp_year"
          handleChange={props.onInputChanged}
          value={props.exp_year || steps[4].exp_year}
        />
      </div>
      <div className="cvc">
        <FormInput
          label="Cryptogramme visuel"
          placeholder="Cryptogramme visuel"
          name="cvc"
          id="cvc"
          handleChange={props.onInputChanged}
          value={props.cvc || steps[4].cvc}
        />
        <FontAwesomeIcon icon={faCreditCard} size="2xl" />
      </div>
      {!props.hideCardName && (
        <FormInput
          label="Nom du titulaire de la carte"
          placeholder="Nom du titulaire de la carte"
          name="card_name"
          id="card_name"
          handleChange={props.onInputChanged}
          value={props.card_name || steps[4].card_name}
        />
      )}
      <div className="checkbox-group">
        <CheckBox
          name="accept"
          id="accept"
          onChange={props.onInputChanged}
          checked={props.accept || steps[4].accept}
        />
        <label htmlFor="accept">
          En cochant cette case, vous acceptez nos{' '}
          <span className="brand">conditions bancaires.</span>
        </label>
      </div>
    </form>
  );
}
