import React from 'react';
import './FormInput.scss';

export default function FormInput(props) {
  let fail = props.fail;

  return (
    <label
      htmlFor={props.id}
      className={`formInput ${fail ? 'formInput--fail' : ''}`}
    >
      {props.prepend && <div className="input__left">{props.prepend}</div>}

      <div className="input__body">
        {props.type === 'select' ? (
          <select name={props.name} id={props.id} onChange={props.handleChange}>
            {(props.options ?? []).map((option, i) => {
              return <option value={option.value} key={i}>{option.text}</option>;
            })}
          </select>
        ) : (
          <input
            type={props.type ?? 'text'}
            placeholder={props.placeholder}
            required={props.required}
            name={props.name}
            id={props.id}
            disabled={props.disabled}
            value={props.value}
            onChange={props.handleChange}
            onKeyDown={props.handleKeyDown}
          />
        )}
        <span>{props.label}</span>
      </div>
    </label>
  );
}
