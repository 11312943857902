import React, { useState, useEffect } from 'react';
import './Step1.scss';

import { useTranslation } from 'react-i18next';
import { Box, Select, TextInput } from 'grommet';

import FormInput from '../../../../components/FormInput/FormInput';
import Button from '../../../../components/Button/Button';

import useDeliveryStepper from '../../../../hooks/useDeliveryStepper';
import useApiV1 from '../../../../hooks/useApiV1';

export function Step1(props) {
  const { t } = useTranslation();
  const [substep, setSubstep] = useState(1);

  const { currentStep, steps, updateStateData } = useDeliveryStepper();

  const nextSubstep = () => {
    if (!canGoToNextStep()) return;

    if (substep === 2) {
      props.nextStep();
      return;
    }
    setSubstep((substep) => substep + 1);
  };

  const previousStep = async () => {
    if (substep == 2) {
      setSubstep((substep) => substep - 1);
    }
  }

  const canGoToNextStep = () => {
    if (substep == 1) {
      if (steps[currentStep].pickup_address.length === 0 || steps[currentStep].pickup_company.length === 0) return false;
    } else {
      let res = true;
      for (const [key, value] of Object.entries(steps[currentStep])) {
        if (key != 'pickup_additional' && key != 'pickup_add') {
          if (!value) {
            res = false;
          }
        }
      }

      return res;
    }

    return true;
  };

  const onDataChange = (newData) => {
    updateStateData({ ...steps[currentStep], ...newData });
  };

  const onInputChanged = (e) => {
    const d = {};
    if (e.target.type === 'checkbox') {
      d[e.target.name] = e.target.checked;
    } else {
      d[e.target.name] = e.target.value;
    }
    onDataChange(d);
  };

  useEffect(() => {
    console.log(steps);
  }, [steps]);

  return (
    <>
      <div className="header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="heading">{t('newdelivery.stepper.step1.title')}</h1>
          <h4>{t('newdelivery.stepper.step')} 1</h4>
        </div>
      </div>
      <div className="body">
        <p className="subtitle">{t('newdelivery.stepper.step1.subtitle')}</p>
        <Substep1
          customContainerStyle={substep == 2 ? {display: 'none'} : {}}
          data={steps[currentStep]}
          onDataChange={onDataChange}
          handleInputChange={onInputChanged}
          updatePickupPointOnMap={props.updatePickupPointOnMap}
        />
        {substep == 2 && (
          <Substep2
            data={steps[currentStep]}
            handleInputChange={onInputChanged}
          />
        )}
      </div>
      <div className="footer">
        {substep == 1 && (
          <div></div>
        )}
        {substep == 2 && (
          <Button
            label={t('labels.previous')}
            handleClick={previousStep}
            white
          />
        )}
        <Button
          label={t('labels.next')}
          handleClick={nextSubstep}
          disabled={!canGoToNextStep()}
        />
      </div>
    </>
  );
}

function Substep1(props) {
  const { t } = useTranslation();
  const { getCities, getStores } = useApiV1();

  const [suggestions, setSuggestions] = useState([]);
  const [showStores, setShowStores] = useState(false);
  const [selectStores, setSelectStores] = useState([]);
  const [store, setStore] = useState(null);

  return (
    <Box gap='small' style={props.customContainerStyle}>
      <TextInput
        name="pickup_address"
        value={props.data['pickup_address']}
        onChange={(e) => {
          let searchText = e.target.value;
          if (searchText.length > 2) {
            getCities(searchText).then((res) => {
              if (res.data?.cities?.length > 0) {
                let aCities = res.data.cities;
                let aSuggestions = aCities.map(oCity => ({
                  label: oCity.name,
                  value: oCity.id
                }))
                setSuggestions(aSuggestions);
              }
            })
          }
          return props.handleInputChange(e);
        }}
        onSuggestionSelect={(e) => {
          props.handleInputChange({ 
            target: { 
              name: "pickup_address",
              value: e.suggestion.label
            }
          });

          getStores(e.suggestion.value).then((res) => {
            if (res.data?.stores?.length > 0) {
              let aStores = res.data.stores;
              setSelectStores(aStores);
            }
          });
          setShowStores(true);
        }}
        suggestions={suggestions}
        defaultSuggestion={1}
        aria-label={t('labels.search-city')}
        placeholder={t('labels.search-city')}
      />
      <Select
        disabled={selectStores.length < 1}
        options={selectStores}
        labelKey={(option) => option.name + " - " + option.formattedAddress}
        valueKey="id"
        value={store}
        onChange={({ option }) => {
          props.onDataChange({
            "pickup_storeid": option.id,
            "pickup_address": option.address.street + ', ' + option.address.postcode + ' ' + option.address.city,
            "pickup_company": option.name,
            "pickup_phone": option.phone,
            "pickup_firstname": option.contact.firstName,
            "pickup_name": option.contact.lastName,
            "pickup_email": option.contact.email,
            "pickup_latitude": option.position?.latitude,
            "pickup_longitude": option.position?.longitude,
            "pickup_additional": option.address.comment || ''
          });
          setStore(option);
          if (option.position?.latitude && option.position?.longitude) {
            props.updatePickupPointOnMap(option.position.latitude, option.position.longitude);
          }
        }}
      />
    </Box>
  );
}

function Substep2(props) {
  const { t } = useTranslation();

  return (
    <>
      <FormInput
        label={t('labels.search-address')}
        placeholder={t('labels.search-address')}
        name="pickup_address"
        handleChange={props.handleInputChange}
        value={props.data['pickup_address']}
      />
      <div className="splitted">
        <FormInput
          label={t('labels.firstname')}
          placeholder={t('labels.firstname')}
          name="pickup_firstname"
          disabled
          handleChange={props.handleInputChange}
          value={props.data['pickup_firstname']}
        />
        <FormInput
          label={t('labels.lastname')}
          placeholder={t('labels.lastname')}
          name="pickup_name"
          disabled
          handleChange={props.handleInputChange}
          value={props.data['pickup_name']}
        />
      </div>
      <FormInput
        label={t('labels.company')}
        placeholder={t('labels.company')}
        name="pickup_company"
        disabled
        handleChange={props.handleInputChange}
        value={props.data['pickup_company']}
      />
      <div className="splitted">
        <FormInput
          label={t('labels.phone')}
          placeholder={t('labels.phone')}
          name="pickup_phone"
          disabled
          handleChange={props.handleInputChange}
          value={props.data['pickup_phone']}
        />
        <FormInput
          label={t('labels.email')}
          placeholder={t('labels.email')}
          name="pickup_email"
          disabled
          handleChange={props.handleInputChange}
          value={props.data['pickup_email']}
        />
      </div>
      <FormInput
        label={t('labels.address-additional')}
        placeholder={t('labels.address-additional')}
        name="pickup_additional"
        disabled
        handleChange={props.handleInputChange}
        value={props.data['pickup_additional']}
      />
      {/* <CheckBox
        label={t('labels.addressbook-add')}
        name="pickup_add"
        onChange={props.handleInputChange}
        checked={props.data['pickup_add']}
      /> */}
    </>
  );
}
