import React, { useEffect, useState } from 'react';
import './AccountBilling.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Card from '../../../components/Card/Card';
import { ListItem } from '../../../components/ListItem/ListItem';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import AvailablePaymentTypeItem from '../../../components/AvailablePaymentTypeItem/AvailablePaymentTypeItem';
import CreditCardForm from '../../../components/CreditCardForm/CreditCardForm';
import useStripe from '../../../hooks/useStripe';
import { creditCardValidation, validateCVV, validateExpirationDate } from '../../../utils/Utils';
import useApiV1 from '../../../hooks/useApiV1';

export default function AccountBilling(props) {
  const { t } = useTranslation();
  const { postCreateToken } = useStripe();
  const { getCards, postCard, deleteCard } = useApiV1();

  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showModalCardDelete, setShowModalCardDelete] = useState(false);
  const [cardToDeleteId, setCardToDeleteId] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [accept, setAccept] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
   doLoadCards();
  }, []);

  const doLoadCards = async () => {
    getCards().then(res => {
      if (res.data?.cards && res.data?.cards.length > 0) {
        console.log(cards)
        setCards(res.data.cards);
      }    
    });
  }

  const doPostCreationToken = async () => {
  
    // ------------------------------
    // CC validation
    // ------------------------------

    // 1. Card number
    if (!creditCardValidation(cardNumber)) {
      return toast.error('Le numéro de la carte de crédit ne semble pas correct. Veuillez corriger et réessayer');
    }

    // 2. Expiration dates (month & year)
    if (!validateExpirationDate(expMonth, expYear)) {
      return toast.error('La date de validité de la carte de crédit est erronée. Veuillez corriger et réessayer');
    }

    // 3. CVV
    if (!validateCVV(cvc)) {
      return toast.error('Le numéro CVV est erroné. Veuillez corriger et réessayer');
    }

    if (!accept) {
      return toast.error('Veuillez accepter nos conditions bancaires et réessayer');
    }
    

    // Process payment to Stripe
    var oPayload = {
      card: {
        number: cardNumber,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: cvc,
      }
    };

    postCreateToken(oPayload).then(async (res) => {

      // ✅ OK
      if (res.status < 300) {
        let oNewCard = await res.json();
        if (oNewCard.id) {
          postCard(oNewCard.id).then(resCard => {
            if (resCard.status < 300) {
              toast.success("Carte créée avec succès.");
              return setShowSecondModal(false);
            } else {
              return toast.error(resCard.data?.message);
            }
          });
        } else {
          return toast.error("Oups. Un problème est survenu pendant la sauvegarde de la carte. Veuillez réessayer ultérieurement.");
        }
      } else {
        return toast.error(res.data?.message);
      }

    });


  }

  const onInputChanged = (e) => {
    switch (e.target.name) {
      case 'card_number':
        setCardNumber(e.target.value);
        break;
      case 'cvc':
        setCvc(e.target.value);
        break;
      case 'exp_month':
        setExpMonth(e.target.value);
        break;
      case 'exp_year':
        setExpYear(e.target.value);
        break;
      case 'accept':
        setAccept(e.target.checked);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Card className="container">
        <h1 className="heading">{t('labels.payment-method')}</h1>
        <p>
          Modifiez vos moyens de paiement enregistrés ou bien ajoutez-en un
          nouveu.
        </p>

        <div className="billingType-container">
          {cards.map((card, index) => (
            <ListItem
              key={'card-'+index}
              left={
                <FontAwesomeIcon
                  icon={faCcVisa}
                  size="xl"
                  style={{ color: '#064397' }}
                />
              }
              title={card.brand}
              subtitle={"****" + card.last4 + ' | ' + card.exp_month + '/' + card.exp_year}
              right={<FontAwesomeIcon icon={faTrash} onClick={() => {
                setShowModalCardDelete(true);
                setCardToDeleteId(card.id)
              }} />}
            />
          ))}
        </div>

        <div className="addBilling">
          <Button
            label={`+ Ajouter un moyen`}
            handleClick={() => {
              setShowFirstModal(true);
            }}
          />
        </div>
      </Card>

      {/* Add modal */}
      {showFirstModal && (
        <Modal
          title={t('labels.add')}
          subtitle="Ajouter un nouveau moyen de paiement"
          handleClose={() => {
            setShowFirstModal(false);
          }}
        >
          <div className="availableType-container">
            <AvailablePaymentTypeItem
              handleSelect={() => {
                setShowFirstModal(false);
                setShowSecondModal(true);
              }}
              title={t('labels.credit-card')}
              icon={
                <FontAwesomeIcon
                  icon={faCcVisa}
                  size="xl"
                  style={{ color: '#064397' }}
                />
              }
            />
          </div>
        </Modal>
      )}
      {showSecondModal && (
        <Modal
          title={t('labels.add')}
          subtitle="Renseignez les informations de votre moyen de paiement"
          handleClose={() => setShowSecondModal(false)}
          handleConfirm={() => doPostCreationToken()}
          >
          <CreditCardForm 
            onInputChanged={onInputChanged}
            card_number={cardNumber}
            exp_month={expMonth}
            exp_year={expYear}
            cvc={cvc}
            accept={accept}
            hideCardName={true}
          />
        </Modal>
      )}
      {showModalCardDelete && (
        <Modal
          title={t("labels.warning")}
          handleClose={() => setShowModalCardDelete(false)}
          handleConfirm={() => {
            deleteCard(cardToDeleteId).then(() => {
              doLoadCards();
              setShowModalCardDelete(false);
              toast.success(t("labels.cards-delete-confirmation"));
            }).catch((err) => {
              toast.error(t("labels.cards-delete-error") + (err || ""));
            })
          }}
          confirmText={t("labels.deletecard")}
          danger={true}
        >
          <h3>{t("labels.cards-delete-sentence-one") + '. '}
          <br />
          {t("labels.are-you-sure")}</h3>
        </Modal>
      )}
    </>
  );
}
