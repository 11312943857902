import React, { useState } from 'react';
import './Register.scss';

// Libraries (UI & external libs)
import { Box, Image } from 'grommet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Hooks, constants & in-house things
import Error from '../../components/Error';
import useForm from '../../hooks/useForm';
import useAuth from '../../hooks/useAuth';
import Card from '../../components/Card/Card';
import FormInput from '../../components/FormInput/FormInput';

import imgLogo from '../../assets/img/logo.png';
import Button from '../../components/Button/Button';

export default function Register() {
  const { t } = useTranslation();

  const { values, handleChange } = useForm({
    initialValues: {
      email: '',
      username: '',
      firstname: '',
      name: '',
      company: '',
      phone: '',
      country: '',
      city: '',
      vehicle: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const [mode, setMode] = useState('merchant'); // merchant or courier

  const { registerUser, error } = useAuth();

  const handleRegister = async (e) => {
    e.preventDefault();
    await registerUser(values);
  };

  return (
    <>
      <Box fill className="loginBackground">
        <div className="header">
          <Image fit="cover" src={imgLogo} />
          <h1>coliveri</h1>
        </div>
        <Card className="form-container">
          <h1 className="heading">{t('authentication.signup.title')}</h1>
          <p style={{ fontSize: ".875rem" }}>{t('authentication.signup.subtitle')}</p>

          <div className="switch-container">
            <button
              className={`switch-btn ${
                mode === 'merchant' ? 'switch-btn--selected' : ''
              }`}
              onClick={() => {
                setMode('merchant');
              }}
            >
              {t('labels.merchant')}
            </button>
            <button
              className={`switch-btn ${
                mode === 'courier' ? 'switch-btn--selected' : ''
              }`}
              onClick={() => {
                setMode('courier');
              }}
            >
              {t('labels.courier')}
            </button>
          </div>

          <div className="splitted">
            <FormInput
              name="firstname"
              placeholder={t('labels.firstname')}
              label={t('labels.firstname')}
              value={values.firstname}
              handleChange={handleChange}
            />
            <FormInput
              name="lastname"
              placeholder={t('labels.lastname')}
              label={t('labels.lastname')}
              value={values.name}
              handleChange={handleChange}
            />
          </div>

          {mode === 'merchant' && (
            <FormInput
              name="company"
              placeholder={t('labels.company')}
              label={t('labels.company')}
              value={values.company}
              handleChange={handleChange}
            />
          )}

          <div className="splitted">
            <FormInput
              type="phone"
              name="phone"
              placeholder={t('labels.phone')}
              label={t('labels.phone')}
              value={values.phone}
              handleChange={handleChange}
            />
            <FormInput
              name="username"
              placeholder={t('labels.email')}
              label={t('labels.email')}
              value={values.username}
              handleChange={handleChange}
            />
          </div>

          {(mode === 'courier') &&
          (
            <>
              <div className="splitted">
                <FormInput
                  name="country"
                  placeholder={t('labels.country')}
                  label={t('labels.country')}
                  value={values.country}
                  handleChange={handleChange}
                />
                <FormInput
                  name="city"
                  placeholder={t('labels.city')}
                  label={t('labels.city')}
                  value={values.city}
                  handleChange={handleChange}
                />
              </div>

              <FormInput
                type="select"
                name="vehicle"
                placeholder={t('labels.select-a-vehicle')}
                label={t('labels.select-a-vehicle')}
                value={values.vehicle}
                handleChange={handleChange}
                options={[
                  { value: 'car', text: t('labels.car') },
                  { value: 'bike', text: t('labels.bike') },
                  { value: 'cargo', text: t('labels.cargo') },
                ]}
              />
            </>
          )}

          <div className="splitted">
            <FormInput
              name="password"
              type="password"
              placeholder={t('labels.password')}
              label={t('labels.password')}
              value={values.password}
              handleChange={handleChange}
            />
            <FormInput
              name="passwordConfirm"
              type="password"
              placeholder={t('labels.confirm-password')}
              label={t('labels.confirm-password')}
              value={values.passwordConfirm}
              handleChange={handleChange}
            />
          </div>

          {error && <Error error={error.message} />}

          <div className="inlineForm__submit">
            <Link to="/login" className="link">
              <span>{t('labels.i-have-an-account')}</span>
            </Link>
            <Button
              secondary
              label={t('labels.create-my-account')}
              handleClick={handleRegister}
            />
          </div>
        </Card>

        <div className="footer">
          <a href="#" className="link">
            {t('labels.legal-notices')}
          </a>
          <a href="#" className="link">
            {t('labels.privacy-policy')}
          </a>
          <span className="footer__copyright">© 2023 - Coliveri</span>
        </div>
      </Box>
    </>
  );
}
