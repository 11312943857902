import React, { Suspense } from 'react';

// Libraries (UI & external libs)
import { Grommet } from 'grommet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Hooks, constants & in-house things
import './App.css';
import PrivateRoute from './pages/PrivateRoute';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Account from './pages/Account/Account';
import Landing from './pages/Landing';
import Tracking from './pages/Tracking/Tracking';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound';
import InProgress from './pages/InProgress/InProgress';
import Scheduled from './pages/Scheduled';
import History from './pages/History/History';
import Stores from './pages/Stores/Stores';
import LoadingSuspense from './components/LoadingSuspense';
import { UserContext } from './contexts/UserContext';
import useAuth from './hooks/useAuth';
import { theme } from './constants/theme';
import { ROUTES } from './constants/routes';
import { DeliveryStepperProvider } from './contexts/DeliveryStepperContext';

// Assets
// ...

function App() {
  const { token, getToken, setToken, getUser } = useAuth();

  return (
    <Suspense fallback={<LoadingSuspense />}>
      <Grommet theme={theme} full background={'light-2'}>
        <ToastContainer />
        <UserContext.Provider value={{ token, getToken, setToken, getUser }}>
          <DeliveryStepperProvider>
              <Router>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path={ROUTES.TRACKING} component={Tracking} />
                  <Route path={ROUTES.REGISTER} component={Register} />
                  <Route path={ROUTES.LOGIN} component={Login} />
                  <PrivateRoute path={ROUTES.ACCOUNT.INDEX} component={Account} />
                  <PrivateRoute path={ROUTES.HOME} component={Home} />
                  <PrivateRoute path={ROUTES.INPROGRESS} component={InProgress} />
                  <PrivateRoute path={ROUTES.SCHEDULED} component={Scheduled} />
                  <PrivateRoute path={ROUTES.HISTORY} component={History} />
                  <PrivateRoute path={ROUTES.STORES} component={Stores} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
          </DeliveryStepperProvider>
        </UserContext.Provider>
      </Grommet>
    </Suspense>
  );
}

export default App;
