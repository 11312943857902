import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './AvailablePaymentTypeItem.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AvailablePaymentTypeItem(props) {
  return (
    <div className="availableType" onClick={props.handleSelect}>
      <div className="availableType__left">
        {props.icon}
        <h4>{props.title}</h4>
      </div>
      <div className="availableType__right">
        <FontAwesomeIcon
          icon={faChevronRight}
          className="availableType-details"
        />
      </div>
    </div>
  );
}
