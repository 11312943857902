import React from 'react';
import './StepperRadio.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StepperRadio(props) {
  return (
    <div className="stepperRadio">
      <label htmlFor={props.id} className="stepperRadio__label">
        <div className="stepperRadio__left">
          <div className={props.disabled ? "stepperRadio__icon_disabled" : "stepperRadio__icon"}>
            <FontAwesomeIcon icon={props.icon} color={'yellow'} size="2xl" />
          </div>
          <div className={"stepperRadio__details" + (props.disabled ? '_disabled' : '')}>{props.details}</div>
        </div>
        <div className="stepperRadio__right">
          {props.right}
          <input
            type="radio"
            name={props.name}
            id={props.id}
            disabled={props.disabled}
            value={props.id}
            onChange={props.handleChange}
            checked={props.selected ?? false}
          />
        </div>
      </label>
    </div>
  );
}
