export const ROUTES = {
    HOME:       "/home",
    ACCOUNT:    {
        INDEX:  "/account",
        DETAIlS: "/account/details",
        BILLING: "/account/billing",
        ADDRESSBOOK: "/account/address-book",
        API: "/account/api",
    },
    LOGIN:      "/login",
    REGISTER:   "/register",
    TRACKING:   "/tracking/:deliveryId",
    LANDING:    "/",
    INPROGRESS: "/inprogress",
    SCHEDULED:  "/scheduled",
    HISTORY:    "/history",
    STORES:     "/stores",  
}
  