import { Box, Spinner, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

export default function Loading(props) {

  const { t } = useTranslation();

  return (
    <Box 
      align="center"
      direction="column"
      gap="large"
      pad="large"
      height="small"
      width="100%">
      <Spinner size={"medium"} />
      {(props.showText || undefined) && <Text>{t('labels.loading')}</Text>}
    </Box>
  );
}
