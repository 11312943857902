import React, { useState } from 'react';
import './AccountAddressBook.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { ListItem } from '../../../components/ListItem/ListItem';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import FormInput from '../../../components/FormInput/FormInput';
import Card from '../../../components/Card/Card';

export default function AccountAddressBook(props) {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <Card className="container">
        <h1 className="heading">{t('account.addressbook.title')}</h1>

        <div className="addresses-container">
          <ListItem
            left={<FontAwesomeIcon size="xl" icon={faBookmark} />}
            title="Exemple d'adresse"
            subtitle="6 rue de la république, 69001 Lyon"
            right={<FontAwesomeIcon icon={faPencil} />}
          />
        </div>
        <div className="addAddress">
          <Button
            disabled
            handleClick={() => {
              setShowAddModal(true);
            }}
          >
            + {t('labels.add-address')}
          </Button>
        </div>
      </Card>
      {showAddModal && (
        <Modal
          title={t('labels.new-address')}
          subtitle={t('account.addressbook.provide-address-information')}
          handleClose={() => setShowAddModal(false)}
          handleConfirm={() => setShowAddModal(false)}
        >
          <FormInput
            label={t('labels.address')}
            placeholder={t('labels.address')}
            name="address"
            id="address"
          />

          <div className="splitted">
            <FormInput
              label={t('labels.firstname')}
              placeholder={t('labels.firstname')}
              name="firstname"
              id="firstname"
            />
            <FormInput
              label={t('labels.lastname')}
              placeholder={t('labels.lastname')}
              name="name"
              id="name"
            />
          </div>

          <FormInput
            label={t('labels.company')}
            placeholder={t('labels.company')}
            name="company"
            id="company"
          />

          <div className="splitted">
            <FormInput
              prepend={<FontAwesomeIcon icon={faLanguage} size="xl" />}
              label={t('labels.phone')}
              placeholder={t('labels.phone')}
              name="phone"
              id="phone"
            />
            <FormInput
              label={t('labels.email')}
              placeholder={t('labels.email')}
              name="email"
              id="email"
            />
          </div>
          <FormInput
            label={t('labels.additional-information')}
            placeholder={t('labels.additional-information')}
            name="additional_information"
            id="additional_information"
          />
          <FormInput
            label={t('labels.address-title')}
            placeholder={t('labels.address-title')}
            name="address_title"
            id="address_title"
          />
        </Modal>
      )}
    </>
  );
}
