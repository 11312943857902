import React, { useState, useEffect, useContext } from 'react';
import './Step4.scss';

import ReactDom from 'react-dom';
import { faBolt, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreditCardForm from '../../../../components/CreditCardForm/CreditCardForm';
import StepperRadio from '../../../../components/StepperRadio/StepperRadio';
import Modal from '../../../../components/Modal/Modal';
import AvailablePaymentTypeItem from '../../../../components/AvailablePaymentTypeItem/AvailablePaymentTypeItem';
import Button from '../../../../components/Button/Button';

import useDeliveryStepper from '../../../../hooks/useDeliveryStepper';
import useApiV1 from '../../../../hooks/useApiV1';
import useStripe from '../../../../hooks/useStripe';
import { UserContext } from '../../../../contexts/UserContext';
import { DateInput, MaskedInput } from 'grommet';
import FormInput from '../../../../components/FormInput/FormInput';
import { creditCardValidation, validateCVV, validateExpirationDate } from '../../../../utils/Utils';

export function Step4(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { getUser } = useContext(UserContext);
  const { postJob } = useApiV1();
  const { postPaymentMethod } = useStripe();

  const [timeslot, setTimeslot] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [isPayDisabled, setIsPayDisabled] = useState(true);

  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false);
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);

  const { currentStep, steps, updateStateData } = useDeliveryStepper();

  const onDataChange = (newData) => {
    updateStateData({ ...steps[currentStep], ...newData });
  };

  const onInputChanged = (e) => {
    const d = {};
    if (e.target.type === 'checkbox' || e.target.type === 'radio') {
      d[e.target.name] = e.target.checked;
    } else {
      d[e.target.name] = e.target.value;
    }
    onDataChange(d);
  };

  useEffect(() => {
    if (steps[4].card_number.length > 0 && 
      steps[4].exp_month.length > 0 && 
      steps[4].exp_year.length > 0 && 
      steps[4].cvc.length > 0 &&
      steps[4].card_name.length > 0 &&
      steps[4].accept) {
      setIsPayDisabled(false);
    } else {
      setIsPayDisabled(true);
    }
  }, [steps[4]])

  useEffect(() => {
    if (timeslot.length == 5) {
      onDataChange({ delivery_timeSlot: timeslot });
    }
  }, [timeslot]);

  useEffect(() => { 
    onDataChange({delivery_date: date})
  }, [date])

  const previousStep = async () => {
    props.previousStep();
  }

  const doJobPostStripe = async () => {

    // ------------------------------
    // CC validation
    // ------------------------------

    // 1. Card number
    if (!creditCardValidation(steps[4].card_number)) {
      toast.error('Le numéro de la carte de crédit ne semble pas correct. Veuillez corriger et réessayer');
      return;
    }

    // 2. Expiration dates (month & year)
    if (!validateExpirationDate(steps[4].exp_month, steps[4].exp_year)) {
      toast.error('La date de validité de la carte de crédit est erronée. Veuillez corriger et réessayer');
      return;
    }

    // 3. CVV
    if (!validateCVV(steps[4].cvc)) {
      toast.error('Le numéro CVV est erroné. Veuillez corriger et réessayer');
      return;
    }
    

    // Process payment to Stripe
    var oPayload = {
      type: steps[4].payment_type,
      card: {
        number: steps[4].card_number,
        exp_month: steps[4].exp_month,
        exp_year: steps[4].exp_year,
        cvc: steps[4].cvc,
      }
    };

    postPaymentMethod(oPayload).then(async (res) => {

      // ✅ OK
      if (res.status < 300) {

        var oRes = await res.json();

        // Process the job (without payment)
        let oJob = {
          storeId: steps[1].pickup_storeid,
          assignmentCode: steps[2].delivery_number,
          transportType: steps[3].vehicle,
          dropoffs: [
            {
              packageType: "xsmall",
              packageDescription: steps[2].delivery_content,
              clientReference: steps[2].delivery_number,
              address: steps[2].delivery_address,
              contact: {
                firstName: steps[2].delivery_firstname,
                lastName: steps[2].delivery_name,
                phone: steps[2].delivery_phone,
                email: steps[2].delivery_email,
                //company: steps[2].delivery_company
              },
              comment: steps[2].delivery_additional,
            }
          ],
          paymentInfos: {
            paymentMethodId: oRes.id,
            paymentAmount: steps[3].amount
          }
        };

        postJob(oJob).then((res, err) => {
          if (res.status < 300) {
            history.push("/inprogress");
            return true;
          } else {            
            toast.error(res.data?.message || 'Oops. Something wrong happened. Please try again later.');
            return false;
          }
        });

      // ❌ NOK
      } else {
        toast.error(res.data?.message || 'Oops. Something wrong happened. Please verify your payment method and try again. ' + JSON.stringify(res));
        return false;
      }
       
    });

  }

  const doJobPostBilling = () => {
    // Process the job (without payment)
    let oJob = {
      storeId: steps[1].pickup_storeid,
      assignmentCode: steps[2].delivery_number,
      transportType: steps[3].vehicle,
      dropoffs: [
        {
          packageType: "xsmall",
          packageDescription: steps[2].delivery_content,
          clientReference: steps[2].delivery_number,
          address: steps[2].delivery_address,
          contact: {
            firstName: steps[2].delivery_firstname,
            lastName: steps[2].delivery_name,
            phone: steps[2].delivery_phone,
            email: steps[2].delivery_email,
            //company: steps[2].delivery_company
          },
          comment: steps[2].delivery_additional,
        }
      ]
    };
    
    postJob(oJob).then((res) => {
      if (res.status < 300) {
        history.push("/inprogress")
      } else {
        debugger;
        toast.error(res.data?.message || "Oops, something wrong happened. Try again.")
      }
    });
  }

  return (
    <>
      <div className="header">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="heading">{t('newdelivery.stepper.step4.title')}</h1>
          <h4>{t('newdelivery.stepper.step')} 4</h4>
        </div>
      </div>
      <div className="body">
        <p className="subtitle">{t('newdelivery.stepper.step4.subtitle')}</p>

        <StepperRadio
          icon={faBolt}
          details={<h4>{t('labels.asap')}</h4>}
          name="timetype"
          id="asap"
          handleChange={onInputChanged}
          selected={steps[currentStep].timetype === 'asap'}
        />
        <StepperRadio
          icon={faCalendar}
          details={<h4>{t('labels.later')}</h4>}
          name="timetype"
          id="later"
          disabled
          handleChange={onInputChanged}
          selected={steps[currentStep].timetype === 'later'}
        />
        {steps[currentStep].timetype === 'later' && (
          <div>
            <div className="splitted">
              <FormInput
                label={t('labels.date')}
                type="select"
                name="delivery_date"
                id="delivery_date"
                onChange={onInputChanged}
              />
              <FormInput
                label={t('labels.timeslot')}
                type="select"
                name="delivery_timeSlot"
                id="delivery_timeSlot"
                onChange={onInputChanged}
              />
              <div className="masked-component-date">
                <DateInput
                  format="dd/mm/yyyy"
                  value={date}
                  calendarProps={{
                    size: "small"
                  }}
                  onChange={({ value }) => {
                    setDate(value);
                  }}
                />
              </div>
              <div className="masked-component-input">
                <MaskedInput
                  mask={[
                    {
                      length: [1, 2],
                      options: [
                        '00',
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                        '21',
                        '22',
                        '23',
                        '24',
                      ],
                      regexp: /^1[1-2]$|^[0-9]$/,
                      placeholder: 'hh',
                    },
                    { fixed: ':' },
                    {
                      length: 2,
                      options: ['00', '15', '30', '45'],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: 'mm',
                    },
                  ]}
                  value={timeslot}
                  onChange={(event) => {
                    if (event.target.value.length == 5) {
                      console.log(event.target.value);
                    }
                    setTimeslot(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <Button
          label={t('labels.previous')}
          handleClick={previousStep}
          white
        />
        <Button
          disabled={!steps[currentStep].timetype ?? true}
          label={t('labels.next')}
          handleClick={() => {
            var paymentMethod = getUser().paymentMethod;
            if (paymentMethod === 'billing') {
              doJobPostBilling();
            } else {
              // Ask the user to enter his credit card info
              setShowPaymentTypeModal(true);
            }
          }}
        />
      </div>
      {showPaymentTypeModal &&
        ReactDom.createPortal(
          <Modal
            title={t('labels.payment')}
            subtitle={t('labels.choose-payment-method')}
            handleClose={() => setShowPaymentTypeModal(false)}
            handleConfirm={() => setShowPaymentTypeModal(false)}
            hideCTA
          >
            {/* <AvailablePaymentTypeItem
              title={t('labels.last-used-payment-method')}
              icon={
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  size="xl"
                  style={{ color: '#064397' }}
                />
              }
            /> */}
            <AvailablePaymentTypeItem
              title={t('labels.credit-card')}
              icon={
                <FontAwesomeIcon
                  icon={faCcVisa}
                  size="xl"
                  style={{ color: '#064397' }}
                />
              }
              handleSelect={() => {
                onDataChange({ payment_type: "card" });
                setShowPaymentTypeModal(false);
                setShowCreditCardModal(true);
              }}
            />
          </Modal>,
          document.querySelector('#modal-root')
        )}
      {showCreditCardModal &&
        ReactDom.createPortal(
          <Modal
            title={t('labels.payment')}
            subtitle={t('labels.fill-payment-information')}
            handleClose={() => setShowCreditCardModal(false)}
            handleConfirm={() => {
              doJobPostStripe().then((result) => {
                if (result) {
                  setShowCreditCardModal(false);
                }
              });
            }}
            confirmText={`${t('labels.pay')}` + ' - ' + steps[3].amountTxt }
            confirmDisabled={isPayDisabled}
          >
            <CreditCardForm onInputChanged={onInputChanged} />
          </Modal>,
          document.querySelector('#modal-root')
        )}
    </>
  );
}
