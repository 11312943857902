import React from 'react';
import './ListItem.scss';

export function ListItem(props) {
  return (
    <div className="listItem">
      <div className="listItem__left">{props.left}</div>
      <div className="listItem__center">
        <h4>{props.title}</h4>
        <span>{props.subtitle}</span>
      </div>
      <div className="listItem__right">{props.right}</div>
    </div>
  );
}
