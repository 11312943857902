import React from 'react';
import './Modal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Card from '../Card/Card';
import Button from '../Button/Button';

export default function Modal(props) {
  const { t } = useTranslation();

  return (
    <div className="modal">
      <div className="modal__bg" onClick={props.handleClose}></div>
      <div className="modal__card">
        <Card>
          <div className="modal__card-header">
            <h1 className="heading">{props.title}</h1>
            {props.hideClose ?? false ? (
              ''
            ) : (
              <FontAwesomeIcon
                icon={faXmark}
                size="2xl"
                className="modal__close"
                onClick={props.handleClose}
              />
            )}
          </div>
          <p>{props.subtitle}</p>

          <div>{props.children}</div>

          {props.hideCTA ?? false ? (
            ''
          ) : (
            <div className="modal__footer">
              <button
                type="button"
                className="cancel-btn"
                onClick={props.handleClose}
              >
                {props.cancelText ?? t('labels.cancel')}
              </button>
              <Button
                type="button"
                disabled={props.confirmDisabled}
                label={props.confirmText ?? t('labels.save')}
                handleClick={props.handleConfirm}
                primary
                danger={props.danger || false}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
