import React, { useState, useEffect } from 'react';

import { Box, TextInput } from 'grommet';
import { useTranslation } from 'react-i18next';

import useApiV1 from '../../hooks/useApiV1';
import Store from '../../components/Store/Store';
import './Stores.scss';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button/Button';
import { toast } from 'react-toastify';
import FormInput from '../../components/FormInput/FormInput';
import { isEmailValid, isPhoneValid } from '../../utils/Utils';

export default function Stores() {
  const { t } = useTranslation();
  const { postCompleteAddress, postStore, putStore, getStores, getCities, deleteStore } = useApiV1();
  const [stores, setStores] = useState([]);

  // Store popup (delete)
  const [showModalStoreDelete, setShowModalStoreDelete] = useState(false);
  const [storeToDeleteId, setStoreToDeleteId] = useState(null);
  const [storeToDeleteName, setStoreToDeleteName] = useState("");

  // Store popup (create)
  const [showModalStoreCreation, setShowModalStoreCreation] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [suggestionsStoreAddress, setSuggestionsStoreAddress] = useState([]);
  const [suggestionsStoreCities, setSuggestionsStoreCities] = useState([]);
  const [storeAddress, setStoreAddress] = useState("");
  const [storeCity, setStoreCity] = useState("");
  const [storeCityId, setStoreCityId] = useState("");
  const [storePhone, setStorePhone] = useState("");
  const [storeDescription, setStoreDescription] = useState("");
  const [storeContactFirstname, setStoreContactFirstname] = useState("");
  const [storeContactLastname, setStoreContactLastname] = useState("");
  const [storeContactEmail, setStoreContactEmail] = useState("");
  const [storeContactPhone, setStoreContactPhone] = useState("");

  // Store popup (update)
  const [showModalStoreUpdate, setShowModalStoreUpdate] = useState(false);
  const [storeUpdateId, setStoreUpdateId] = useState("");
  const [storeUpdateName, setStoreUpdateName] = useState("");
  const [suggestionsStoreUpdateAddress, setSuggestionsStoreUpdateAddress] = useState([]);
  const [storeUpdateAddress, setStoreUpdateAddress] = useState("");
  const [storeUpdatePhone, setStoreUpdatePhone] = useState("");
  const [storeUpdateDescription, setStoreUpdateDescription] = useState("");
  const [storeUpdateContactFirstname, setStoreUpdateContactFirstname] = useState("");
  const [storeUpdateContactLastname, setStoreUpdateContactLastname] = useState("");
  const [storeUpdateContactEmail, setStoreUpdateContactEmail] = useState("");
  const [storeUpdateContactPhone, setStoreUpdateContactPhone] = useState("");

  useEffect(() => {
    loadStores();
  }, []);

  const loadStores = async () => {
    const res = await getStores();
    const stores = res.data.stores;
    setStores(stores);
  }

  const cleanFormCreation = () => {
    setStoreName("");
    setSuggestionsStoreAddress([]);
    setSuggestionsStoreCities([]);
    setStoreAddress("");
    setStoreCity("");
    setStorePhone("");
    setStoreDescription("");
    setStoreContactFirstname("");
    setStoreContactLastname("");
    setStoreContactEmail("");
    setStoreContactPhone("");
  }

  const cleanFormUpdate = () => {
    setStoreUpdateName("");
    setSuggestionsStoreUpdateAddress([]);
    setStoreUpdateAddress("");
    setStoreUpdatePhone("");
    setStoreUpdateDescription("");
    setStoreUpdateContactFirstname("");
    setStoreUpdateContactLastname("");
    setStoreUpdateContactEmail("");
    setStoreUpdateContactPhone("");
  }

  const updateStore = async () => {
    // Check all fields
    if (storeUpdateName === "") {
      return toast.error("Veuillez entrer le nom du store (champ obligatoire)");
    }
    if (storeUpdateAddress === "") {
      return toast.error("Veuillez entrer l'adresse du store (champ obligatoire)");
    }
    if (storeUpdatePhone === "") {
      return toast.error("Veuillez entrer le numéro de téléphone du store (champ obligatoire)");
    }
    if (storeUpdateDescription === "") {
      return toast.error("Veuillez entrer la description du store (champ obligatoire)");
    }
    if (storeUpdateContactFirstname === "") {
      return toast.error("Veuillez entrer le prénom du contact du store (champ obligatoire)");
    }
    if (storeUpdateContactLastname === "") {
      return toast.error("Veuillez entrer le nom du contact du store (champ obligatoire)");
    }
    if (storeUpdateContactEmail === "") {
      return toast.error("Veuillez entrer l'email du store (champ obligatoire)");
    }
    if (storeUpdateContactPhone === "") {
      return toast.error("Veuillez entrer le numéro de téléphone du contact du store (champ obligatoire)");
    }
    if (!isPhoneValid(storeUpdatePhone)) {
      return toast.error("Le numéro de téléphone du store n'est pas valide. Veuillez corriger et réessayer.");
    }
    if (!isPhoneValid(storeUpdateContactPhone)) {
      return toast.error("Le numéro de téléphone du contact du store n'est pas valide. Veuillez corriger et réessayer.");
    }
    if (!isEmailValid(storeUpdateContactEmail)) {
      return toast.error("L'email du contact n'est pas valide. Veuillez corriger et réessayer.");
    }

    // Update the store
    putStore(storeUpdateId, {
      name: storeUpdateName,
      address: storeUpdateAddress,
      description: storeUpdateDescription,
      phone: storeUpdatePhone,
      contact: {
        firstName: storeUpdateContactFirstname,
        lastName: storeUpdateContactLastname,
        email: storeUpdateContactEmail,
        phone: storeUpdateContactPhone
      }
    }).then((res) => {
      cleanFormUpdate();
      setShowModalStoreUpdate(false);
      loadStores();
    }).catch((err) => {
      return toast.error("Oups. Une erreur est survenue pendant la mise à jour du store | " + JSON.stringify(err));
    })
  }

  const submitNewStore = async () => {
    // Check all fields
    if (storeName === "") {
      return toast.error("Veuillez entrer le nom du store (champ obligatoire)");
    }
    if (storeAddress === "") {
      return toast.error("Veuillez entrer l'adresse du store (champ obligatoire)");
    }
    if (storeCity === "") {
      return toast.error("Veuillez entrer la ville du store (champ obligatoire)");
    }
    if (storePhone === "") {
      return toast.error("Veuillez entrer le numéro de téléphone du store (champ obligatoire)");
    }
    if (storeDescription === "") {
      return toast.error("Veuillez entrer la description du store (champ obligatoire)");
    }
    if (storeContactFirstname === "") {
      return toast.error("Veuillez entrer le prénom du contact du store (champ obligatoire)");
    }
    if (storeContactLastname === "") {
      return toast.error("Veuillez entrer le nom du contact du store (champ obligatoire)");
    }
    if (storeContactEmail === "") {
      return toast.error("Veuillez entrer l'email du store (champ obligatoire)");
    }
    if (storeContactPhone === "") {
      return toast.error("Veuillez entrer le numéro de téléphone du contact du store (champ obligatoire)");
    }
    if (!isPhoneValid(storePhone)) {
      return toast.error("Le numéro de téléphone du store n'est pas valide. Veuillez corriger et réessayer.");
    }
    if (!isPhoneValid(storeContactPhone)) {
      return toast.error("Le numéro de téléphone du contact du store n'est pas valide. Veuillez corriger et réessayer.");
    }
    if (!isEmailValid(storeContactEmail)) {
      return toast.error("L'email du contact n'est pas valide. Veuillez corriger et réessayer.");
    }

    // Submit the new store
    postStore({
      name: storeName,
      address: storeAddress,
      cityId: storeCityId,
      phone: storePhone,
      contact: {
        firstName: storeContactFirstname,
        lastName: storeContactLastname,
        email: storeContactEmail,
        phone: storeContactPhone
      },
      description: storeDescription
    }).then((res) => {
      cleanFormCreation();
      setShowModalStoreCreation(false);
      loadStores();
    }).catch((err) => {
      return toast.error("Oups. Une erreur est survenue pendant la mise à jour du store | " + JSON.stringify(err));
    })
  }

  return (
    <Box pad="large">
      <div className='row'>
        <h1 className="heading">{stores.length} {t('stores.titlesentence')}</h1>
        <Button
            label={t('labels.add-new-store')}
            handleClick={() => setShowModalStoreCreation(true)}
            disabled={false}
        />
      </div>
      <div className="stores">
        {stores.map((store, index) => {
          return (
            <Store 
              key={store.id}
              name={store.name}
              phone={store.phone}
              formattedAddress={store.formattedAddress}
              nbIntegrationsDone={store.integrations.length || 0}
              nbIntegrationsAvailable={store.availableIntegrators.length || 0}
              currency={store.currency}
              contact={store.contact}
              setShowModalStoreUpdate={() => {
                setStoreUpdateId(store.id)
                setStoreUpdateName(store.name)
                setStoreUpdateAddress(store.formattedAddress);
                setStoreUpdatePhone(store.phone);
                setStoreUpdateDescription(store.description || "");
                setStoreUpdateContactFirstname(store.contact.firstName);
                setStoreUpdateContactLastname(store.contact.lastName);
                setStoreUpdateContactEmail(store.contact.email);
                setStoreUpdateContactPhone(store.contact.phone);
                setShowModalStoreUpdate(true);
              }}
              setShowModalStoreDelete={() => {
                setStoreToDeleteId(store.id);
                setStoreToDeleteName(store.name);
                setShowModalStoreDelete(true);
              }}
            />
          )
        })}
      </div>
      {showModalStoreUpdate && (
        <Modal
          title={t("labels.store-update-title")}
          subtitle={t("labels.store-update-subtitle")}
          handleClose={() => {
            setShowModalStoreUpdate(false)}
          }
          handleConfirm={() => {
            updateStore();
          }}
          confirmText={t("labels.update")}
        >
          <h3>{t("labels.store-section-store")}</h3>
          <FormInput
            label={t('labels.store-name')}
            placeholder={t('labels.store-name')}
            name="store-name"
            handleChange={(e) => setStoreUpdateName(e.target.value)}
            value={storeUpdateName}
          />
          <TextInput
            name="store-address"
            value={storeUpdateAddress}
            onChange={(e) => {
              let address = e.target.value;
              if (address.length > 4) {
                postCompleteAddress(address).then((res) => {
                  if (res.data?.predictions?.length > 0) {
                    let aPredictions = res.data.predictions;
                    let aSuggestions = aPredictions.map(oPrediction => ({
                      label: oPrediction.formattedAddress,
                      value: oPrediction.formattedAddress,
                      lat: oPrediction.latitude,
                      lng: oPrediction.longitude,
                    }))
                    setSuggestionsStoreUpdateAddress(aSuggestions);
                  }
                })
              }
              setStoreUpdateAddress(e.target.value);
            }}
            onSuggestionSelect={(e) => {
              setStoreUpdateAddress(e.suggestion.label);
            }}
            suggestions={suggestionsStoreUpdateAddress}
            defaultSuggestion={1}
            aria-label={t('labels.store-address')}
            placeholder={t('labels.store-address')}
            className='txtInputSuggestions'
          />
          <FormInput
            label={t('labels.store-phone')}
            placeholder={t('labels.store-phone')}
            name="store-phone"
            handleChange={(e) => setStoreUpdatePhone(e.target.value)}
            value={storeUpdatePhone}
          />
          <FormInput
            label={t('labels.store-description')}
            placeholder={t('labels.store-description')}
            name="store-description"
            handleChange={(e) => setStoreUpdateDescription(e.target.value)}
            value={storeUpdateDescription}
          />
          <h3>{t("labels.store-section-contact")}</h3>
          <div className="splitted">
            <FormInput
              label={t('labels.store-contact-firstname')}
              placeholder={t('labels.store-contact-firstname')}
              name="store-contact-firstname"
              handleChange={(e) => setStoreUpdateContactFirstname(e.target.value)}
              value={storeUpdateContactFirstname}
            />
            <FormInput
              label={t('labels.store-contact-lastname')}
              placeholder={t('labels.store-contact-lastname')}
              name="store-contact-lastname"
              handleChange={(e) => setStoreUpdateContactLastname(e.target.value)}
              value={storeUpdateContactLastname}
            />
          </div>
          <FormInput
            label={t('labels.store-contact-email')}
            placeholder={t('labels.store-contact-email')}
            name="store-contact-email"
            handleChange={(e) => setStoreUpdateContactEmail(e.target.value)}
            value={storeUpdateContactEmail}
          />
          <div className="splitted">
            <FormInput
              label={t('labels.store-phone')}
              placeholder={t('labels.store-phone')}
              name="store-phone"
              handleChange={(e) => setStoreUpdateContactPhone(e.target.value)}
              value={storeUpdateContactPhone}
            />
          </div>
        </Modal>
      )}
      {showModalStoreCreation && (
        <Modal
          title={t("labels.store-creation-title")}
          subtitle={t("labels.store-creation-subtitle")}
          handleClose={() => {
            cleanFormCreation();
            setShowModalStoreCreation(false)}
          }
          handleConfirm={() => {
            submitNewStore();
          }}
          confirmText={t("labels.add")}
        >
          <h3>{t("labels.store-section-store")}</h3>
          <FormInput
            label={t('labels.store-name')}
            placeholder={t('labels.store-name')}
            name="store-name"
            handleChange={(e) => setStoreName(e.target.value)}
            value={storeName}
          />
          <TextInput
            name="store-address"
            value={storeAddress}
            onChange={(e) => {
              let address = e.target.value;
              if (address.length > 4) {
                postCompleteAddress(address).then((res) => {
                  if (res.data?.predictions?.length > 0) {
                    let aPredictions = res.data.predictions;
                    let aSuggestions = aPredictions.map(oPrediction => ({
                      label: oPrediction.formattedAddress,
                      value: oPrediction.formattedAddress,
                      lat: oPrediction.latitude,
                      lng: oPrediction.longitude,
                    }))
                    setSuggestionsStoreAddress(aSuggestions);
                  }
                })
              }
              setStoreAddress(e.target.value);
            }}
            onSuggestionSelect={(e) => {
              setStoreAddress(e.suggestion.label);
            }}
            suggestions={suggestionsStoreAddress}
            defaultSuggestion={1}
            aria-label={t('labels.store-address')}
            placeholder={t('labels.store-address')}
            className='txtInputSuggestions'
          />
          <div className="splitted">
            <TextInput
              name="store-city"
              value={storeCity}
              onChange={(e) => {
                let citySearch = e.target.value;
                if (citySearch.length > 2) {
                  getCities(citySearch).then((res) => {
                    if (res.data?.cities?.length > 0) {
                      let aPredictions = res.data.cities;
                      let aSuggestions = aPredictions.map(oPrediction => ({
                        label: oPrediction.name,
                        value: oPrediction.id
                      }))
                      setSuggestionsStoreCities(aSuggestions);
                    }
                  })
                }
                setStoreCity(e.target.value);
              }}
              onSuggestionSelect={(e) => {
                debugger
                setStoreCity(e.suggestion.label);
                setStoreCityId(e.suggestion.value);
              }}
              suggestions={suggestionsStoreCities}
              defaultSuggestion={1}
              aria-label={t('labels.store-city')}
              placeholder={t('labels.store-city')}
              className='txtInputSuggestions'
            />
            <FormInput
              label={t('labels.store-phone')}
              placeholder={t('labels.store-phone')}
              name="store-phone"
              handleChange={(e) => setStorePhone(e.target.value)}
              value={storePhone}
            />
          </div>
          <FormInput
            label={t('labels.store-description')}
            placeholder={t('labels.store-description')}
            name="store-description"
            handleChange={(e) => setStoreDescription(e.target.value)}
            value={storeDescription}
          />
          <h3>{t("labels.store-section-contact")}</h3>
          <div className="splitted">
            <FormInput
              label={t('labels.store-contact-firstname')}
              placeholder={t('labels.store-contact-firstname')}
              name="store-contact-firstname"
              handleChange={(e) => setStoreContactFirstname(e.target.value)}
              value={storeContactFirstname}
            />
            <FormInput
              label={t('labels.store-contact-lastname')}
              placeholder={t('labels.store-contact-lastname')}
              name="store-contact-lastname"
              handleChange={(e) => setStoreContactLastname(e.target.value)}
              value={storeContactLastname}
            />
          </div>
          <FormInput
            label={t('labels.store-contact-email')}
            placeholder={t('labels.store-contact-email')}
            name="store-contact-email"
            handleChange={(e) => setStoreContactEmail(e.target.value)}
            value={storeContactEmail}
          />
          <div className="splitted">
            <FormInput
              label={t('labels.store-phone')}
              placeholder={t('labels.store-phone')}
              name="store-phone"
              handleChange={(e) => setStoreContactPhone(e.target.value)}
              value={storeContactPhone}
            />
          </div>
        </Modal>
      )}
      {showModalStoreDelete && (
        <Modal
          title={t("labels.warning")}
          handleClose={() => setShowModalStoreDelete(false)}
          handleConfirm={() => {
            deleteStore(storeToDeleteId).then(() => {
              loadStores();
              setShowModalStoreDelete(false);
              toast.success(t("labels.store-delete-confirmation"));
            }).catch((err) => {
              toast.error(t("labels.store-delete-error") + (err || ""));
            })
          }}
          confirmText={t("labels.deletestore")}
          danger={true}
        >
          <h3>{t("labels.store-delete-sentence-one") + " " + storeToDeleteName}<br/> 
          {t("labels.are-you-sure")}</h3>
        </Modal>
      )}
    </Box>
  );
}
