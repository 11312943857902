import React, { useContext } from 'react';

// Libraries (UI & external libs)
import { Box, Button, Image, Text } from 'grommet';
import { Link, Redirect } from 'react-router-dom';

// Hooks, constants & in-house things
import { UserContext } from '../contexts/UserContext';

// Assets
import imgLogo from '../assets/img/logo.png';

export default function Landing() {
  const { getToken } = useContext(UserContext);

  if (getToken()) {
    return <Redirect to="/home" />;
  }

  return (
    <Box align="center" fill background="white">
      <Box width="medium" align="center">
        <Box height="small" width="small" margin={{ bottom: 'medium' }}>
          <Image fit="contain" src={imgLogo} />
          <Text>Empty Landing Page</Text>
          <Link to={'/login'}>
            <Button primary label="Open Login Page" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
