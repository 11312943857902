import React, { useEffect } from 'react';
import './Stepper.scss';

import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { Step3 } from './Step3/Step3';
import { Step4 } from './Step4/Step4';
import useDeliveryStepper from '../../../hooks/useDeliveryStepper';

export function Stepper(props) {
  const { currentStep, incrementCurrentStep, decrementCurrentStep, clearState } =
    useDeliveryStepper();

  useEffect(() => {
    clearState();
  }, []);

  const nextStep = () => {
    incrementCurrentStep();
  };

  const previousStep = () => {
    decrementCurrentStep();

    // If going from step 2 to step 1 --> reset the form as the user will start from the beginning (kind of a reset)
    if (currentStep == 2) {
      clearState();
    }
  }

  return (
    <>
      <div className="stepper">
        {currentStep === 1 && <Step1 nextStep={nextStep} updatePickupPointOnMap={props.updatePickupPointOnMap} />}
        {currentStep === 2 && <Step2 nextStep={nextStep} previousStep={previousStep} updateDeliveryPointOnMap={props.updateDeliveryPointOnMap} />}
        {currentStep === 3 && <Step3 nextStep={nextStep} previousStep={previousStep} />}
        {currentStep === 4 && <Step4 nextStep={nextStep} previousStep={previousStep} />}
      </div>
    </>
  );
}
