import React, { useState, useEffect } from 'react';
import './InProgress.scss';

import { Box } from 'grommet';

import Delivery from '../../components/Delivery/Delivery';
import useApiV1 from '../../hooks/useApiV1';
import Loading from '../../components/Loading';

export default function InProgress() {
  const { getActiveJobs, getStore } = useApiV1();

  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [stores, setStores] = useState([]);
  const [deliveries, setDeliveries] = useState([]);

  useEffect(() => {
    getActiveJobs().then((v) => {
      setJobs(v.data.jobs ?? []);
    });
  }, []);

  useEffect(() => {
    (async () => {
      const strs = [];
      for (const job of jobs) {
        const d = await getStore(job.store.id);
        if (d.status === 200) {
          console.log(d)
          if (strs.findIndex((s) => s.id === d.data.id) === -1) {
            strs.push(d.data);
          }
        }
      }
      setStores(strs);
    })();
  }, [jobs]);

  useEffect(() => {
    let deliveries = [];

    for (const job of jobs) {
      debugger;
      const store = stores.find((store) => store.id === job.store.id);

      if (store) {
        for (const delivery of job.deliveries) {
          deliveries.push({
            delivery: { ...delivery },
            store: { ...store },
          });
        }
      }
    }

    setDeliveries(deliveries);
    if (deliveries.length > 0) {
      setLoading(false);
    }
  }, [jobs, stores]);

  return (

    loading ? (
      <Box justify="center" align="center" fill className="loginBackground">
        <Loading />
      </Box>
    ) : (
      <Box pad="large">
        <h1 className="heading">{deliveries.length} livraison(s) en cours</h1>
        <div className="deliveries">
          {deliveries.map(({ delivery, store }) => {
            return (
              <Delivery
                id={delivery.id}
                key={'delivery-' + delivery.id}
                status={delivery.status}
                reference={delivery.clientReference}
                trackingSecureToken={delivery.trackingSecureToken}
                departure={{
                  title: store?.address?.street || '-',
                  subtitle: `${store.address.postcode} ${store.address.city}`,
                }}
                arrival={{
                  title: delivery?.dropoff?.address?.street || '-',
                  subtitle: `${delivery.dropoff.address.postcode} ${delivery.dropoff.address.city}`,
                }}
              />
            );
          })}
        </div>
      </Box>
    )
  );
}
