import React, { useState, useEffect, useRef } from 'react';
import './Tracking.scss';

import ReactMapGL, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { Box, Meter } from 'grommet';
import useApiV1 from '../../hooks/useApiV1';
import { env } from '../../constants/env';
import imgProfileUser from '../../assets/img/profile-user.png';

export default function Tracking(props) {
  mapboxgl.accessToken = env.MAPBOX_API_KEY;
  const mapContainer = useRef(null);
  const { getDeliveryTracking } = useApiV1();

  const [timerRefresh, setTimerRefresh] = useState(1);
  const [riderLng, setRiderLng] = useState(null);
  const [riderLat, setRiderLat] = useState(null);

  const [deliveryId, setDeliveryId] = useState(null);
  const [deliveryTrackingToken, setDeliveryTrackingToken] = useState(null);

  const INITIAL_LAT_CENTER_FRANCE = 46.227638;
  const INITIAL_LNG_CENTER_FRANCE = 2.213749;
  const INITIAL_ZOOM = 5;

  useEffect(() => {
    const interval = setInterval(() => {
      if (timerRefresh >= 100) {
        setTimerRefresh(0);
        displayRiderPosition();
      } else {
        var newValue = Math.round(timerRefresh) + 2;
        setTimerRefresh(newValue);
      }
    }, 500);
  
    return () => clearInterval(interval);
  }, [timerRefresh]);

  useEffect(() => {
    displayRiderPosition();
  }, [mapContainer]);

  const displayRiderPosition = () => {
    console.log('[GET] New rider position call to backend')
    getDeliveryTracking(props.deliveryId || deliveryId, props.deliveryTrackingToken || deliveryTrackingToken)
      .then((res) => {
        var oLastPosition = res?.data?.rider?.lastPositionRecorded;
        if (oLastPosition.latitude && oLastPosition.longitude) {
          setRiderLat(oLastPosition.latitude);
          setRiderLng(oLastPosition.longitude);
          mapContainer.current.flyTo({
            center: [oLastPosition.longitude, oLastPosition.latitude],
            zoom: 17,
            speed: 1.2
          });
        }
      })
      .catch((err) => {
        debugger
      })
  }

  return (
    <Box justify="center" align="center" fill>
      <Meter
        size='xlarge'
        thickness='xsmall'
        value={timerRefresh}
        aria-label="meter"
      />
      <ReactMapGL
        ref={mapContainer}
        initialViewState={{
          latitude: INITIAL_LAT_CENTER_FRANCE,      // center of France (lat)
          longitude: INITIAL_LNG_CENTER_FRANCE,     // center of France (lng)
          zoom: INITIAL_ZOOM,
        }}
        interactive={true}
        mapStyle="mapbox://styles/mapbox/light-v11"
        attributionControl={true}
      >
        {riderLat && (
          <Marker longitude={riderLng} latitude={riderLat}>
            <div className='ripple'>
              <img alt={"Pin rider"} src={imgProfileUser} height={128} width={128} className='pin-rider'/>
            </div>
          </Marker>
        )}
      </ReactMapGL>
    </Box>
  );
}
