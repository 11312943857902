import React, { useState, useEffect } from 'react';

import { Box } from 'grommet';

import useApiV1 from '../../hooks/useApiV1';
import Delivery from '../../components/Delivery/Delivery';

export default function History() {
  const { getJobsHistory, getStore } = useApiV1();
  const [deliveries, setDeliveries] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getJobsHistory();
      const jobs = res.data.jobs;

      const strs = [];
      for (const job of jobs) {
        const d = await getStore(job.store.id);
        if (d.status === 200) {
          if (strs.findIndex((s) => s.id === d.data.id) === -1) {
            strs.push(d.data);
          }
        }
      }

      let deliveries = [];

      for (const job of jobs) {
        const store = strs.find((store) => store._id === job.store.id);
        if (store) {
          for (const delivery of job.deliveries) {
            deliveries.push({
              delivery: { ...delivery },
              store: { ...store },
            });
          }
        }
      }

      setDeliveries(deliveries);
    })();
  }, []);

  return (
    <Box pad="large">
      <h1 className="heading">{deliveries.length} livraisons passées</h1>
      <div className="deliveries">
        {deliveries.map(({ delivery, store }) => {
          return (
            <Delivery
              hideTime
              key={delivery.id}
              reference={delivery.clientReference}
              departure={{
                title: store.address.street,
                subtitle: `${store.address.postcode} ${store.address.city}`,
              }}
              arrival={{
                title: delivery.dropoff.address.street,
                subtitle: `${delivery.dropoff.address.postcode} ${delivery.dropoff.address.city}`,
              }}
            />
          );
        })}
      </div>
    </Box>
  );
}
