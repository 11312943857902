import React, { useState, useEffect, useCallback } from 'react';
import './AccountAPI.scss';
import useApiV1 from '../../../hooks/useApiV1';

import Card from '../../../components/Card/Card';
import Button from '../../../components/Button/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import { Select } from 'grommet';
import { toast } from 'react-toastify';

export default function AccountAPI() {
  const { t } = useTranslation();
  const { getStores, putDisableIntegration, getHubriseAuthUrl, getTriggerSearchRiderStatusesForIntegration } = useApiV1();
  const [stores, setStores] = useState(null);
  const [myIntegrations, setMyIntegrations] = useState([]);
  const [externalPopup, setExternalPopup] = useState(null);

  useEffect(() => {
    refreshData();
  }, []);

  const openModal = async (url) => {
    const width = 700;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = 'Window title';

    const popup = window.open(
      url,
      title,
      `width=${width},height=${height},left=${left},top=${top}`
    );
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup) return;

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
    }, [500]);
  }, [externalPopup]);

  const refreshData = () => {
    getStores().then((res) => {
      setStores(res.data?.stores ?? []);
    });
  };

  useEffect(async () => {
    loadMyIntegrations();
  }, [stores])

  const loadMyIntegrations = async () => {
    if (!stores || stores.length === 0) return;
    const integrations = [];

    for (const store of stores) {
      for (const integration of store.integrations) {
        if (!integration.disabled) {
          var resStatuses = await getTriggerSearchRiderStatusesForIntegration(integration.integrator);
          integrations.push({
            name: integration.integrator,
            storeId: store.id,
            triggerSearchRiderStatus: integration.triggerSearchRiderStatus,
            statuses: resStatuses.data?.statuses || []
          });
        }
      }
    }
    setMyIntegrations(integrations);
  }

  const displayAvailableIntegrations = useCallback(() => {
    if (!stores) return [];
    const integrations = [];

    for (const store of stores) {
      if (store.availableIntegrators?.length > 0) {
        integrations.push(
          ...store.availableIntegrators.map((integrator) => {
            return {
              storeId: store.id,
              name: integrator,
            };
          })
        );
      }
    }
    return integrations;
  }, [stores]);

  return (
    <>
      <h1 className="heading">{t('account.api.available-integrations')}</h1>
      <div className="integrations">
        {displayAvailableIntegrations().map((integrator) => {
          return (
            <Integration
              key={Math.random()}
              name={integrator.name}
              storeId={integrator.storeId}
              hideStatus
              handleClick={async () => {
                const res = await getHubriseAuthUrl(
                  integrator.storeId,
                  integrator.name
                );
                if (!res?.data?.url) return;
                openModal(res.data.url);
              }}
            />
          );
        })}
      </div>
      <h1 className="heading">{t('account.api.my-integrations')}</h1>
      <div className="integrations">
        {myIntegrations.map((integration) => {
          return (
            <Integration
              key={Math.random()}
              name={integration.name}
              status={integration.triggerSearchRiderStatus}
              statuses={integration.statuses}
              storeId={integration.storeId}
              actionText="Retirer"
              integrations={myIntegrations}
              refreshData={refreshData}
              handleClick={() => {
                putDisableIntegration(
                  integration.storeId,
                  integration.name
                ).finally(() => {
                  refreshData();
                });
              }}
            />
          );
        })}
      </div>
    </>
  );
}

const Integration = function (props) {
  const { t } = useTranslation();
  const { patchTriggerSearchRiderStatusForIntegration } = useApiV1();
  return (
    <Card className="integration">
      <img 
        src={"https://koust.net/wp-content/uploads/2020/09/HubRise-Logo.png"}
        className="integration__logo"
      />
      <div className="integration__header">
        <h4>{props.name}</h4>
      </div>
      <div className="integration__body">
        {!props.hideStatus ?? false ? (
          <div className="integration__category">
            <h5>{t('labels.status')}</h5>
            <Select
              options={props.statuses}
              value={props.status}
              margin={{vertical: 'small'}}
              onChange={({ value: nextValue }) => {
                patchTriggerSearchRiderStatusForIntegration(props.storeId, props.name, nextValue).then(res => {
                  if (res.status < 300) {
                    props.refreshData();
                    toast.success("Le statut de " + props.name + " a été mis à jour avec succès")
                  } else {
                    toast.error(res.data?.message || "Une erreur s'est produite pendant la mise à jour du statut de " + props.name);
                  }
                });
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="integration__footer">
        <Button handleClick={props.handleClick}>
          {props.actionText ?? t('labels.connect')}
        </Button>
      </div>
    </Card>
  );
};
